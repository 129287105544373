import { apiRequest} from './index'

export const getProductsRequest = (page, limit, search, company='', status='') => {
  return apiRequest(
    `/admin/products?page=${page}&limit=${limit}&term=${search}&sellerCompany=${company}&status=${status}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getProductRequest = (id) => {
  return apiRequest(
    `/admin/products/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const createProductRequest = (product) => {
  return apiRequest(
    `/admin/products`,
    {
      method: 'POST',
      body: JSON.stringify(product)
    },
    true
  )
}

export const updateProductRequest = (product) => {
  return apiRequest(
    `/admin/products/${product._id}`,
    {
      method: 'PUT',
      body: JSON.stringify(product)
    },
    true
  )
}

export const deleteProductRequest = (id) => {
  return apiRequest(
    `/admin/products/${id}`,
    {
      method: 'DELETE'
    },
    true
  )
}

export const getProductsStatsRequest = () => {
  return apiRequest(
    `/admin/product-stats`,
    {
      method: 'GET'
    },
    true
  )
}

