import React from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from "reactstrap";
import moment from 'moment';
import Datetime from "react-datetime";

import { getUserRequest } from "api/user";
import { updateClientRequest } from 'api/client'
import { isValidDateOfBirth, getFormattedDate } from "helpers/date";
import { updateSellerRequest } from "api/seller";

class UserForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
            data: null,
			error: null,
            loading: false
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			try {
				const user = await getUserRequest(id);
				this.setState({ user, data: user[user.role]})
			} catch(err) {
				this.setState({
					error: err.message || 'There was an error fetching user'
				});
			}
		} else {
			// case for adding a user
			this.setState({
				user: {
					name: '',
				}
			})
		}
	}

	goTo = path => this.props.history.push(path);

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			data: {
				...prevState.data,
				[name]: value,
			},
		}));
	}

	onChangeCheckbox = (e) => {
		const { target } = e;
		const { name, checked } = target;

		this.setState(prevState => ({
			user: {
				...prevState.user,
				[name]: checked,
			},
		}));
	}

	save = async () => {
		const { data, user } = this.state;

        this.setState({loading: true})
		
		try {
            if (user.role === 'client') {
                await updateClientRequest(data)  
            } else {
				await updateSellerRequest(data)  
            }

			this.goTo('/users');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving user'
			})
		}

        this.setState({loading: false})
	}

	onChangeAddress = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			data: {
				...prevState.data,
				address: {
					...(prevState.data.address || {}),
					[name]: value
				}
			}
		}));
	}

	render() {

		const {
			user = {},
			data = {},
			error = null,
            loading
		} = this.state;

		const {
			_id,
			email,
			company,
			role,
			emailVerified,
			isAdmin,
			createdAt,
			updatedAt
		} = user || {};

        const {
            firstName,
            lastName,
            address,
            gender,
            phone,
            dateOfBirth
        } = data || {}

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">Edit User</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/users')}
									>
										Back
 					                </Button>
								</CardHeader>
								<CardBody>
									{!user
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>Email</Label>
												<Input
													type="text"
													name="email"
													value={email || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
											<FormGroup>
												<Label>Role</Label>
												<Input
													type="select"
													name="role"
													value={role || ''}
													onChange={this.onChangeField}
                                                    disabled
												>
													<option value=''>-- SELECT --</option>
													<option value="client">Client</option>
													<option value="seller">Seller</option>
													<option value="driver">Driver</option>
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Company Name</Label>
												<Input
													type="text"
													name="companyName"
													value={company?.name || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
                                            <FormGroup>
												<Row>
													<Col sm={4}>
														<div className="form-check">
															<label className="form-check-label" style={{opacity: '0.7'}}>
																	<input 
                                                                        className="form-check-input"
                                                                        onChange={this.onChangeCheckbox} 
                                                                        name="emailVerified"
                                                                        type="checkbox"
                                                                        checked={!!emailVerified}
                                                                        disabled
                                                                    />
																	    Email Verified
																	<span className="form-check-sign">
																			<span className="check"></span>
																	</span>
															</label>
														</div>
													</Col>
													<Col sm={4}>
														<div className="form-check" style={{opacity: '0.7'}}>
															<label className="form-check-label">
																	<input 
                                                                        className="form-check-input"
                                                                        onChange={this.onChangeCheckbox}
                                                                        name="isAdmin"
                                                                        type="checkbox"
                                                                        checked={!!isAdmin} 
                                                                        disabled
                                                                    />
																	Is Admin
																	<span className="form-check-sign">
																			<span className="check"></span>
																	</span>
															</label>
														</div>
													</Col>
												</Row>
											</FormGroup>
                                            <hr/>
											<FormGroup>
												<Label>First Name</Label>
												<Input
													type="text"
													name="firstName"
													value={firstName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Last Name</Label>
												<Input
													type="text"
													name="lastName"
													value={lastName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Gender</Label>
												<Input
													type="select"
													name="gender"
													value={gender || ''}
													onChange={this.onChangeField}
												>
													<option value=''>-- SELECT --</option>
													<option value={"male"}>Male</option>
													<option value={"female"}>Female</option>
													<option value={"other"}>Other</option>
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Birthday</Label>
												<Datetime
													inputProps={{
														className: "form-control",
														placeholder: "Birthday",
														name: "dateOfBirth"
													}}
													timeFormat={false}
													value={typeof dateOfBirth === 'string' ? moment(dateOfBirth).format("MM/DD/YYYY") : dateOfBirth}
													onChange={(date) => this.onChangeField({target: { value: date, name: "dateOfBirth" }})}
													isValidDate={isValidDateOfBirth}
													closeOnSelect={true}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Telephone</Label>
												<Input
													type="text"
													name="phone"
													value={phone || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											{/*<Label style={{ fontSize: 18 }} className="my-2">Address</Label>
											<FormGroup>
												<Label>Street Address 1</Label>
												<Input
													type="text"
													name="streetAddressOne"
													value={address?.streetAddressOne || ''}
													onChange={this.onChangeAddress}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Street Address 2</Label>
												<Input
													type="text"
													name="streetAddressTwo"
													value={address?.streetAddressTwo || ''}
													onChange={this.onChangeAddress}
												/>
											</FormGroup>
											<FormGroup>
												<Label>City</Label>
												<Input
													type="text"
													name="city"
													value={address?.city || ''}
													onChange={this.onChangeAddress}
												/>
											</FormGroup>
											<FormGroup>
												<Label>State</Label>
												<Input
													type="text"
													name="state"
													value={address?.state || ''}
													onChange={this.onChangeAddress}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Zip Code</Label>
												<Input
													type="text"
													name="zipCode"
													value={address?.zipCode || ''}
													onChange={this.onChangeAddress}
												/>
											</FormGroup>*/}
											{_id ? <><FormGroup>
												<Label>Created At</Label>
												<Input
													type="text"
													name="createdAt"
													value={getFormattedDate(createdAt || '')}
													readOnly
												/>
											</FormGroup>
												<FormGroup>
													<Label>Updated At</Label>
													<Input
														type="text"
														name="updatedAt"
														value={getFormattedDate(updatedAt || '')}
														readOnly
													/>
												</FormGroup>
											</> : null}
										</>
									}
									{error ? <div className="alert alert-danger mt-3">
											<span>{error}</span>
										</div> : null
									}
								</CardBody>
								{user && 
                                    <Col>
                                        <Button 
                                            color="primary"
                                            disabled={loading}
                                            className="mb-4"
                                            onClick={this.save}
                                        >
                                            {loading
                                                ? "...loading"
                                                : "Save Changes"
                                            }
                                        </Button>
                                    </Col>
                                }
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default UserForm;

