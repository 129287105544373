import { apiRequest} from './index'

export const getCompaniesRequest = (page, limit, search, role, corporation) => {
  return apiRequest(
    `/admin/companies?page=${page}&limit=${limit}&search=${search}&role=${role}&corporation=${corporation}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCompanyRequest = (id) => {
  return apiRequest(
    `/admin/companies/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const updateCompanyRequest = (company) => {
  return apiRequest(
    `/admin/companies/${company._id}`,
    {
			method: 'PUT',
			body: JSON.stringify(company)
    },
    true
  )
}

export const createCompanyRequest = (company) => {
  return apiRequest(
    `/admin/companies`,
    {
			method: 'POST',
			body: JSON.stringify(company)
    },
    true
  )
}

export const deleteCompanyRequest = (companyId) => {
  return apiRequest(
    `/admin/companies/${companyId}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const getCompanyStatsRequest = () => {
  return apiRequest(
    `/admin/company-stats`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteCompanyProductRequest = (companyId, productId) => {
  return apiRequest(
    `/admin/companies/${companyId}/product/${productId}/delete`,
    {
			method: 'PUT'
    },
    true
  )
}

export const editCompanyProductRequest = (companyId, productId, body) => {
  return apiRequest(
    `/admin/companies/${companyId}/product/${productId}/edit`,
    {
			method: 'PUT',
      body: JSON.stringify(body)
    },
    true
  )
}

export const saveProductIntoCompanyRequest = (body) => {
  return apiRequest(
    `/me/companies/save-product`,
    {
			method: 'POST',
      body: JSON.stringify(body)
    },
    true
  )
}
