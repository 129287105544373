import React, { Component } from 'react';
import { forEachSeries } from 'p-iteration';
import ImageCard from '../ImageCard/ImageCard';
import { UncontrolledAlert } from 'reactstrap';
import { saveImageFileRequest, saveImagesArrayRequest, deleteImagesArrayRequest } from "../../api/image";

class ProductUploadArea extends Component {

	state = {
		isImagesUpdated: false
	}

	saveImagesFile = async (result, product_id) => {
		try {
			const {
				images,
			} = this.props;
	
			const {
				onSaveSuccess,
				onSaveFailure,
			} = this.props;
	
			let uploadedFilesUrlArray = [];
	
			if (images) {
				await forEachSeries(images, async (image) => {
					let uploadedFileUrl = image.src;
					if (image.file) {
						// check if file exists then add it to bucket.
						let uploadedFileResponse = await saveImageFileRequest(product_id, image.file);
	
						if (uploadedFileResponse.Location)
							uploadedFileUrl = uploadedFileResponse.Location
					}
					uploadedFilesUrlArray.push(uploadedFileUrl)
				});
			}
			result.images = uploadedFilesUrlArray;
			await saveImagesArrayRequest(product_id, { images: uploadedFilesUrlArray })
			onSaveSuccess(result, product_id)
		} catch(err) {
			this.props.onSaveFailure();
		}
	}

	saveImages = async (result, product_id) => {
		try{
			const {
				deleteImagesFromBucket,
			} = this.props;

			//delete from bucket then save images file.
			await deleteImagesArrayRequest(product_id, { images: deleteImagesFromBucket })
			this.saveImagesFile(result, product_id)
		} catch(err) {
			console.log(err, "Err")
		}
	}

	onAddImage = (file, src) => {
		const { images, parent } = this.props;
		const newImages = [...images];
		newImages.push({ file, src });
		parent.setState({ images: newImages });
		this.setState({
			isImagesUpdated: true
		});
	}

	onUpdateImage = (file, src, index) => {
		const { images, parent } = this.props;
		const newImages = [...images];
		this.onDeleteImage(index, false); // delete subscribed image.
		newImages[index] = { file, src };
		parent.setState({ images: newImages });
		this.setState({
			isImagesUpdated: true
		});
	}

	onSetMainImage = (index) => {
		const { images, parent } = this.props;
		const newImages = [...images];
		const workingImage = { ...newImages[index] };
		newImages.splice(index, 1);
		newImages.unshift(workingImage);
		parent.setState({ images: newImages });
	}

	onDeleteImage = (index, removeFromImages = true) => {
		const { images, deleteImagesFromBucket, parent } = this.props;
		const newImages = [...images];
		const workingImage = { ...newImages[index] };

		const newDeleteImages = [...deleteImagesFromBucket]

		const newState = {};

		if (!workingImage.file) {
			// If `.file` is empty, It means that It is an image that already exists in the bucket and must be removed.
			// If already exists, add to the `deleteImagesFromBucket` array, otherwise, only remove it from the `images` array.
			newDeleteImages.push(workingImage.src);
			newState.deleteImagesFromBucket = newDeleteImages;
		}

		if (removeFromImages) {
			newImages.splice(index, 1);
			newState.images = newImages;
		}

		if (!workingImage.file || removeFromImages)
			parent.setState(newState);
		this.setState({
			isImagesUpdated: true
		});
	}

	renderImages = () => {
		const { totalOfCards, images } = this.props;

		let imageCards = []
		let remainingCards = totalOfCards;
		//rendering existing images
		if (images) {
			imageCards = images.map((image, index) => {
				return (
					<ImageCard
						key={index}
						src={image.src}
						id={"image" + index}
						starActive={index === 0}
						onDelete={() => this.onDeleteImage(index)}
						onChange={(file, src) => this.onUpdateImage(file, src, index)}
						onSetMain={index === 0 ? () => { } : () => this.onSetMainImage(index)}
					/>
				)
			});
			remainingCards -= images.length
		}

		//rendering empty images input
		const emptyImageCards = Array.apply(null, { length: remainingCards }).map((image, index) => {
			return (
				<ImageCard
					key={totalOfCards + index}
					id={"remainingFiles" + index}
					onDelete={() => this.onDeleteImage(index)}
					onChange={(file, src) => this.onAddImage(file, src)}
				/>
			)
		});

		return imageCards.concat(emptyImageCards);
	}

	render() {
		const { isImagesUpdated } = this.state;
		return (
			<>
				{this.renderImages()}
				{isImagesUpdated ? <UncontrolledAlert className="mt-2" style={{fontSize: 12}} color="info">
					Images will only be saved when saving the product
				</UncontrolledAlert> : null}
			</>
		);
	}
}

export default ProductUploadArea;