import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
// Components

import ConfirmationModal from "../components/ConfirmationModal/ConfirmationModal";
import ApprovalsModal from "../components/ApprovalsModal/ApprovalsModal";

// Requests
import {
  getApprovalsRequest,
  handleDriverApprovalRequest,
  handleWithdrawalApprovalRequest,
  handleProductApprovalRequest
} from "api/approvals";
// Helpers
import { formatPrice } from "helpers/pricing";
import { formatApprovalType } from "helpers/strings";
import { formatArea } from "helpers/strings";
import { formatTransactionStatus } from "helpers/status";
import { getCategoryNameById } from "helpers/category";

class Approvals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approvals: null,
      approvalRequest: null,
      type: '',
      approvalDetails: null,
    };
    this.timeout = null;
  }

  async componentDidMount() {
    await this.getApprovals();
  }

  getApprovals = async () => {
    const approvals = await getApprovalsRequest();
    this.setState({
      approvals,
    });
  };

  
  openApprovalConfirmation = (approvalRequest) => () => {
    this.setState({
      approvalRequest,
    });
    this.approvalConfirmationModal.toggle();
  };

  openRejectionConfirmation = (approvalRequest) => () => {
    this.setState({
      approvalRequest,
    });
    this.rejectConfirmationModal.toggle();
  };

  approveRequest = async () => {
    const approval = this.state.approvalRequest;
    switch (approval.type) {
      case "driver":
        await handleDriverApprovalRequest({ approval, status: "Approved" });
        break;
      case "withdrawal":
        await handleWithdrawalApprovalRequest({ approval, status: "Approved" });
        break;
      case "product":
        await handleProductApprovalRequest({ approval, status: "Approved" });
        break;
      default:
        break;
    }
    this.getApprovals();
  };

  rejectRequest = async () => {
    const approval = this.state.approvalRequest;
    switch (approval.type) {
      case "driver":
        await handleDriverApprovalRequest({ approval, status: "Rejected" });
        break;
      case "withdrawal":
        await handleWithdrawalApprovalRequest({ approval, status: "Rejected" });
        break;
      case "product":
        await handleProductApprovalRequest({ approval, status: "Rejected" });
        break;
      default:
        break;
    }
    this.getApprovals();
  };

  getApprovalDetail = (approval) => {
    let detail = "";
    if (approval.type === "driver") {
      detail = `Driver Sign Up`;
    }
    if (approval.type === "withdrawal") {
      detail = `Withdrawal requested: $${formatPrice(approval.amount)}.`;
    }
    if (approval.type === "product") {
      detail = `Product created`;
    }
    return detail;
  };

  openConfirmation = (approval) => () => {
    this.setState({
      approvalDetails: approval,
    });
    this.approvalDetailModal.toggle();
  }

  onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getApprovals();
		}, 300);
	}

  render() {
    const { approvals } = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="d-inline-block">
                    Approvals
                  </CardTitle>

                  {/* <FormGroup>
										<Input
											type="select"
											name="approval"
											value={approval}
											onChange={this.onChangeFilter}
										>
											<option value=''>-- SELECT TYPE--</option>
											<option value="driver">Driver</option>
											<option value="withdrawal">Withdrawal</option>
											<option value="product">Product</option>
								</Input>
									</FormGroup> */}
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>User</th>
                        <th>Product</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvals && approvals.length ? (
                        approvals.map((approval) => {
                          return (
                            <tr key={approval._id}>
                              <td>{moment(approval.updatedAt).format("MM/DD HH:MM")}</td>
                              <td>{formatApprovalType(approval.type) || "N/A"}</td>
                              <td>
                                {approval.type === "driver" && approval.user ? (
                                  <Link to={`/user?id=${approval.user._id}`}>
                                    {approval.user.email}
                                  </Link>
                                ) : approval.type === "withdrawal" &&
                                  approval.driver ? (
                                  <Link
                                    to={`/user?id=${approval.driver.user._id}`}
                                  >
                                    {approval.driver.user.email}
                                  </Link>
                                ) : (
                                  "N/A"
                                )}

                              </td>
                              <td>
                                {approval.type === "product"
                                  ? <Link to={`/product?id=${approval._id}`}>
                                      {approval.name}
                                    </Link>
                                  : "N/A"
                                }
                              </td>
                              <td>{approval.status.charAt(0).toUpperCase() + approval.status.slice(1)}</td>
                              <td>{this.getApprovalDetail(approval)}</td>
                              <td className="text-right">
                                <Button
                                  className="btn-link btn-icon"
                                  color="info"
                                  id={`tooltip-view-${approval._id}`}
                                  size="sm"
                                  onClick={this.openConfirmation(approval)}
                                >
                                  <i className="tim-icons icon-zoom-split" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-view-${approval._id}`}
                                >
                                  View
                                </UncontrolledTooltip>
                                {/* <Button
                                  className="btn-link btn-icon"
                                  color="success"
                                  id={`tooltip-approval-${approval._id}`}
                                  size="sm"
                                  onClick={this.openApprovalConfirmation(
                                    approval
                                  )}
                                >
                                  <i className="tim-icons icon-check-2" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-approval-${approval._id}`}
                                >
                                  Approve
                                </UncontrolledTooltip>
                                <Button
                                  className="btn-link btn-icon"
                                  color="danger"
                                  id={`tooltip-reject-${approval._id}`}
                                  size="sm"
                                  onClick={this.openRejectionConfirmation(
                                    approval
                                  )}
                                >
                                  <i className="tim-icons icon-simple-remove" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-reject-${approval._id}`}
                                >
                                  Reject
                                </UncontrolledTooltip> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6">
                            There's no approval pending at the moment
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ConfirmationModal
            ref={(el) => (this.approvalConfirmationModal = el)}
            title="Approve Request?"
            buttons={[
              {
                title: "Cancel",
                className: "btn-primary-override btn btn-secondary",
              },
              {
                title: "Approve",
                className: "btn-danger-override btn btn-success",
                onClick: this.approveRequest,
              },
            ]}
          >
            Are you sure you want to approve this item?
          </ConfirmationModal>
          <ConfirmationModal
            ref={(el) => (this.rejectConfirmationModal = el)}
            title="Reject Request?"
            buttons={[
              {
                title: "Cancel",
                className: "btn-primary-override btn btn-secondary",
              },
              {
                title: "Reject",
                className: "btn-danger-override btn btn-danger",
                onClick: this.rejectRequest,
              },
            ]}
          >
            Are you sure you want to reject this item?
          </ConfirmationModal>

          <ApprovalsModal
              ref={el => this.approvalDetailModal = el}
              title={<span style={{fontSize: 18}}>Approvals Details</span>}
              buttons={[
                { title: 'Approve', className: 'btn-primary-override btn btn-primary', onClick: this.openApprovalConfirmation(this.state.approvalDetails) },
                { title: 'Reject', className: 'btn-danger-override btn btn-danger', onClick: this.openRejectionConfirmation(this.state.approvalDetails) }
              ]}
            >
              {this.state.approvalDetails ? (
                <>
                  {this.state.approvalDetails.type === "product" && (

                    <>
                      <p>ID: {(this.state.approvalDetails._id) || "N/A"}</p>
                      <p>SKU: {(this.state.approvalDetails.sku) || "N/A"}</p>
                      <p>Name: {(this.state.approvalDetails.name) || "N/A"}</p>
                      <p>Status: {(this.state.approvalDetails.status) || "N/A"}</p>
                      <p>Description: {formatApprovalType(this.state.approvalDetails.description) || "N/A"}</p>
                      <p>images: {(<a href={this.state.approvalDetails.images} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                      <p>category Level One: {getCategoryNameById(this.state.approvalDetails.categoryLevelOne) || "N/A"}</p>
                      <p>category Level Two: {getCategoryNameById(this.state.approvalDetails.categoryLevelTwo) || "N/A"}</p>
                      <p>Specifications: {(this.state.approvalDetails.specifications) || "N/A"}</p>
                      <p>Size: {(this.state.approvalDetails.size) || "N/A"}</p>
                      <p>Color: {(this.state.approvalDetails.color) || "N/A"}</p>
                      <p>Created At: {(this.state.approvalDetails.createdAt) || "N/A"}</p>
                      <p>Updated At: {(this.state.approvalDetails.updatedAt) || "N/A"}</p>
                    </>
                  )}

                  {this.state.approvalDetails.type === "driver" && (
                    <>
                      <div style={{border: '1px solid black', padding: 10 }}>
                        <span style={{ color: 'black', fontSize: 22 }}>Driver Details: </span>
                        <p>First Name: {(this.state.approvalDetails.firstName) || "N/A"}</p>
                        <p>Last Name: {(this.state.approvalDetails.lastName) || "N/A"}</p>
                        <p>Email: {(this.state.approvalDetails.user.email) || "N/A"}</p>
                        <p>Phone number: {(this.state.approvalDetails.phoneNumber) || "N/A"}</p>
                        <p>Driver ID: {(this.state.approvalDetails._id) || "N/A"}</p>
                        <p>Driver Area: {formatArea(this.state.approvalDetails.area) || "N/A"}</p>
                        <p>Avatar Image: {(<a href={this.state.approvalDetails.avatar} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Drivers License Image: {(<a href={this.state.approvalDetails.driversLicenseImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                      </div>
                      <div style={{border: '1px solid black', padding: 10, marginTop: 10 }}  >
                        <span style={{ color: 'black', fontSize: 22 }}>Vehicle Details: </span>
                        <p>Insurance Card Image: {(<a href={this.state.approvalDetails.insuranceCardImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Car Registration Image: {(<a href={this.state.approvalDetails.carRegistrationImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Car Image 1: {(<a href={this.state.approvalDetails.carFirstImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Car Image 2: {(<a href={this.state.approvalDetails.carSecondImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Car Image 3: {(<a href={this.state.approvalDetails.carThirdImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                        <p>Car Image 4: {(<a href={this.state.approvalDetails.carFourthImage} target="_blank" rel="noreferrer">Show Image</a>) || "N/A"}</p>
                      </div>
                      <p>Created At: {moment(this.state.approvalDetails.createdAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}</p>
                      <p>Updated At: {moment(this.state.approvalDetails.updatedAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}</p>
                    </>
                  )}

                  {this.state.approvalDetails.type === "withdrawal" && (
                    <>
                      <div style={{border: '1px solid black', padding: 10 }}>
                        <span style={{ color: 'black', fontSize: 22 }}>Withdrawal Details: </span>
                        <p>Amount: {`$ ${formatPrice(this.state.approvalDetails.amount)}`}</p>
                        <p>Requested At: {moment(this.state.approvalDetails.createdAt).format("MM/DD HH:mm:ss") || "N/A"}</p>
                      </div>
                      <div style={{border: '1px solid black', padding: 10, marginTop: 10 }}  >
                        <span style={{ color: 'black', fontSize: 22 }}>Driver Details: </span>
                        <p>Driver ID: {(this.state.approvalDetails.driver._id) || "N/A"}</p>
                        <p>Email: {(this.state.approvalDetails.driver.user.email) || "N/A"}</p>
                        <p>Member Since: {moment(this.state.approvalDetails.driver.createdAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}</p>
                        <p>Bank Name: {(this.state.approvalDetails.driver.user.bankName) || "N/A"}</p>
                        <p>Account Type: {(this.state.approvalDetails.driver.user.accountType) || "N/A"}</p>
                        <p>Account Number: {(this.state.approvalDetails.driver.user.accountNumber) || "N/A"}</p>
                        <p>Routing Number: {(this.state.approvalDetails.driver.user.routingNumber) || "N/A"}</p>
                      </div>
                    </>
                  )}
                </>
                
              ) : (
                <p>There's no approval pending at the moment</p>
              )}
          </ApprovalsModal>

        </div>
      </>
    );
  }
}

export default Approvals;


