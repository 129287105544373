import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
	Input,
	FormGroup
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Async from 'react-select/async';

import PaginationComponent from "components/Custom/Pagination";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { calcStartEndPage } from 'helpers/pagination';
import { formatPrice } from "helpers/pricing";
import { formatTransferStatus } from "helpers/status";
import { getTransfersRequest, getTransfersStatsRequest, deleteTransferRequest } from 'api/transfer';
import { getCompaniesRequest } from "api/company";
import { getCorporationsRequest } from "api/corporation";
import { runsStatus } from "constants/status";
import { formatRunStatus } from "helpers/status";

class Transfers extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			transfers: null,
			page: 0,
			total: 0,
			limit: 25,
			pages: 0,
			current_page: 0,
			start_page: 0,
			end_page: 9,
			first_page: 0,
			last_page: 0,
			stats: null,
			search: '',
			deleteTransferId: '',
			status: '',
			client: '',
			corporation: '',
			originCompany: '',
			destinationCompany: ''
		}
		this.timeout = null;
	}

	async componentDidMount() {
		await this.getTransfers();
		await this.getStats();
	}

	getTransfers = async () => {
		const { limit, page, search, status, corporation, originCompany, destinationCompany } = this.state;
		const transfersObj = await getTransfersRequest(page, limit, search, status, corporation && corporation.value || '', originCompany && originCompany.value || '', destinationCompany && destinationCompany.value || '');
		const transfers = transfersObj.transfers;
		const total = transfersObj.total;
		const pages = Math.ceil(total / limit);
		const last_page = pages - 1;
		const end_page = pages > 10 ? 9 : pages - 1;

		this.setState({
			transfers,
			total,
			pages,
			last_page,
			end_page
		})
	}

	getStats = async () => {
		const stats = await getTransfersStatsRequest();
		this.setState({
			stats
		})
	}

	goTo = path => this.props.history.push(path);

	goToPage = async (page) => {
		const { limit, pages, search } = this.state;
		const transfersObj = await getTransfersRequest(page, limit, search);
		const transfers = transfersObj.transfers;
		const { start_page, end_page } = calcStartEndPage(page, pages);

		this.setState({
			transfers: transfers,
			current_page: page,
			start_page: start_page,
			end_page: end_page
		})
	}

	onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getTransfers();
		}, 300);
	}

	deleteTransfer = async () => {
		const { deleteTransferId } = this.state;
		await deleteTransferRequest(deleteTransferId);
		this.getTransfers();
	}

	openConfirmation = (transferId) => () => {
		this.setState({
			deleteTransferId: transferId
		});
		this.deleteConfirmationModal.toggle();
	}

	loadCompanies = (role) => async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 0, text, role, '');
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

	loadSellerCorporations = () => async (text, callback) => {
		if(text) {
			const corporationsResponse = await getCorporationsRequest(0, 0, text, 'seller', '');
			const corporations = corporationsResponse.corporations.map(corporation => {
				return {
					data: corporation,
					label: corporation.name,
					value: corporation._id
				}
			})
			callback(corporations);
		}
	}

	onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}
	
	render() {

		const {
			transfers,
			pages,
			current_page,
			start_page,
			end_page,
			stats,
			search,
			status,
			corporation,
			originCompany,
			destinationCompany
		} = this.state;

		const totalTransfers = stats ? stats.transferCount : 0;
		const totalOpenTransfers = stats ? stats.openTransfersCount : 0;

		return (
			<>
				<div className="content">
					<Row>
						<Col xs="12">
							<Card className="card-chart">
								<CardHeader>
									<Row>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-bag-16" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Total Transfers</p>
																<CardTitle tag="h3">{totalTransfers}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
												</CardFooter>
											</Card>
										</Col>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Open Transfers</p>
																<CardTitle tag="h3">{totalOpenTransfers}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
												</CardFooter>
											</Card>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Transfers</CardTitle>
									<FormGroup>
										<Input
											type="select"
											name="status"
											value={status}
											onChange={this.onChangeFilter}
										>
											<option value={''}>-- SELECT STATUS --</option>
											{runsStatus.map(status => (
												<option value={status}>{formatRunStatus(status)}</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<Async
											value={corporation}
											name="corporation"
											options={[]}
											loadOptions={this.loadSellerCorporations()}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('corporation')}
											isClearable
											placeholder="Select Corporation..."
										/>
									</FormGroup>
									<FormGroup>
										<Async
											value={originCompany}
											name="originCompany"
											options={[]}
											loadOptions={this.loadCompanies('seller')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('originCompany')}
											isClearable
											placeholder="Select Origin Company..."
										/>
									</FormGroup>
									<FormGroup>
										<Async
											value={destinationCompany}
											name="destinationCompany"
											options={[]}
											loadOptions={this.loadCompanies('seller')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('destinationCompany')}
											isClearable
											placeholder="Select Destination Company..."
										/>
									</FormGroup>
								</CardHeader>
								<CardBody>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th>Date</th>
												<th>Corporation</th>
												<th>Origin</th>
												<th>Destination</th>
												<th>Products</th>
												<th>Status</th>
												<th>Delivery Fee</th>
												<th className="text-right">Actions</th>
											</tr>
										</thead>
										<tbody>
											{transfers && transfers.length
												? transfers.map((transfer) => {
													return (
														<tr key={transfer._id}>
															<td>{moment(transfer.createdAt).format('DD/MM/YYYY')}</td>
															<td>{transfer.corporation 
																? <Link to={`/corporation?id=${transfer.corporation._id}`}>{transfer.corporation.name}</Link>
																: "N/A"
															}</td>
															<td>{transfer.originCompany 
																? <Link to={`/company?id=${transfer.originCompany._id}`}>{transfer.originCompany.name}</Link>
																: "N/A"
															}</td>
															<td>{transfer.destinationCompany 
																? <Link to={`/company?id=${transfer.destinationCompany._id}`}>{transfer.destinationCompany.name}</Link>
																: "N/A"
															}</td>
															<td>{transfer.products && transfer.products.length}</td>
															<td>{formatTransferStatus(transfer.status)}</td>
															<td>$ {formatPrice(transfer.deliveryFee)}</td>
															<td className="text-right">
																<Button
																	className="btn-link btn-icon"
																	color="success"
																	id={`tooltip-view-${transfer._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/transfer?id=${transfer._id}`)}
																>
																	<i className="tim-icons icon-zoom-split" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-view-${transfer._id}`}
																>
																	View
                                								</UncontrolledTooltip>
																{/* <Button
																	className="btn-link btn-icon"
																	color="info"
																	id={`tooltip-edit-${transfer._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/transfer/edit?id=${transfer._id}`)}
																>
																	<i className="tim-icons icon-pencil" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-edit-${transfer._id}`}
																>
																	Editar
                                								</UncontrolledTooltip> */}
																<Button
																	className="btn-link btn-icon"
																	color="danger"
																	id={`tooltip-delete-${transfer._id}`}
																	size="sm"
																	onClick={this.openConfirmation(transfer._id)}
																>
																	<i className="tim-icons icon-simple-remove" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-delete-${transfer._id}`}
																>
																	Delete
                                								</UncontrolledTooltip>
															</td>
														</tr>
													)
												})
												: <tr>
													<td colSpan="7">There are no transfers to show</td>
												</tr>
											}
										</tbody>
									</Table>
									{transfers ?.length > 0 &&
										<PaginationComponent
											pages={pages}
											current_page={current_page}
											start_page={start_page}
											end_page={end_page}
											goToPage={this.goToPage}
										/>
                    }
								</CardBody>
							</Card>
						</Col>
					</Row>
					<ConfirmationModal
						ref={el => this.deleteConfirmationModal = el}
						title="Delete Transfer?"
						buttons={[
							{ title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
							{ title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteTransfer }
						]}
					>
						Are you sure you want to delete this transfer?
                    </ConfirmationModal>
				</div>
			</>
		);
	}
}

export default Transfers;
