import React from "react";
import { 
    Button, 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader, 
    CardTitle, 
    Col, 
    FormGroup, 
    Input, 
    Row, 
    Table, 
    UncontrolledTooltip 
} from "reactstrap";
import { Link } from "react-router-dom";
import PaginationComponent from "components/Custom/Pagination";
import { calcStartEndPage } from "helpers/pagination";
import { formatRole } from "helpers/strings";
import { getCompaniesRequest, getCompanyStatsRequest, deleteCompanyRequest } from '../../api/company'
import { getCorporationsRequest } from "api/corporation";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import Async from 'react-select/async';

class Companies extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companies: null,
            page: 0,
            total: 0,
            limit: 25,
            pages: 0,
            current_page: 0,
            start_page: 0,
            end_page: 9,
            first_page: 0,
            last_page: 0,
            search: '',
            role: '',
            stats: null,
            deleteId: '',
            corporation: null
        }
        this.timeout = null;
    }

    componentDidMount() {
        this.getCompanies()
        this.getStats()
    }

    getCompanies = async () => {
        try {
            const { page, limit, search, role, corporation} = this.state
    
            const { companies, total } = await getCompaniesRequest(page, limit, search, role, corporation?.value ?? '')

            console.log(corporation)
    
            const pages = Math.ceil(total / limit);
            const last_page = pages - 1;
            const end_page = pages > 10 ? 9 : pages - 1;
    
            this.setState({
                companies,
                total,
                pages,
                last_page,
                end_page,
            })        
        } catch (error) {
            console.log('error', error)
        }
    }

    getStats = async () => {
        try {
            const stats = await getCompanyStatsRequest();
            console.log(stats)

            this.setState({
                stats
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    goTo = path => this.props.history.push(path)

    goToPage = async (page) => {
        const { limit, pages, search, role } = this.state;
        const { companies } = await getCompaniesRequest(page, limit, search, role, '');
        const { start_page, end_page } = calcStartEndPage(page, pages);

        this.setState({
            companies,
            current_page: page,
            start_page,
            end_page
        })
    }

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }
    
    deleteUser = async () => {
		const { deleteId } = this.state;
        await deleteCompanyRequest(deleteId);
        this.getCompanies()
	}

    onChangeFilter = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getCompanies();
		}, 300);
    }

    loadCorporation = async (text, callback) => {
		if(text) {
			const corporationResponse = await getCorporationsRequest(0, 10, text, '');
			const corporation = corporationResponse.corporations.map(corporation => {
				return {
					data: corporation,
					label: corporation.name,
					value: corporation._id
				}
			})
			callback(corporation);
		}
	}

    onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}

    render () {
        const {
            companies,
            pages,
            current_page,
            end_page,
            start_page,
            stats,
            role,
            corporation
        } = this.state

        const { clientCount = 0, sellerCount = 0 } = stats || {}

        return (
            <div className="content">
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                        <CardHeader>
                            <Row>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-bank" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total client companies</p>
                                                <CardTitle tag="h3">{clientCount}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-bank" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total sellers companies</p>
                                                <CardTitle tag="h3">{sellerCount}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </CardHeader>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle className="d-inline-block" tag="h4">
                                    Companies
                                </CardTitle>
                                {/* <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo('/user/create')}>Add User</Button>
                                <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
                                <FormGroup>
                                    <Input
                                        type="select"
                                        name="role"
                                        value={role || ''}
                                        onChange={this.onChangeFilter}
                                    >
                                        <option value=''>-- SELECT TYPE--</option>
                                        <option value="client">Client</option>
                                        <option value="seller">Seller</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Async
                                        value={corporation}
                                        name="corporation"
                                        options={[]}
                                        loadOptions={this.loadCorporation}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.onSelectChange('corporation')}
                                        isClearable
                                        placeholder="Select Corporation..."
                                    />
                                </FormGroup>
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Owner</th>
                                            <th>Role</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {companies && companies.length > 0 
                                        ? companies.map(({
                                            _id, 
                                            name,
                                            role,
                                            basedIn,
                                            owner
                                        }) => (
                                            <tr key={_id}>
                                                <td>{name || 'N/A'}</td>
                                                <td>{owner 
                                                    ? <Link to={`/user?id=${owner._id}`}>{owner.email}</Link>
                                                    : "N/A"
                                                }</td>
                                                <td>{formatRole(role) || 'N/A'}</td>
                                                <td className="text-right">
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="success"
                                                        id={`tooltip-view-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/company?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-zoom-split" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-view-${_id}`}
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="info"
                                                        id={`tooltip-edit-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/company/edit?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-pencil" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-edit-${_id}`}
                                                    >
                                                        Edit
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="danger"
                                                        id={`tooltip-delete-${_id}`}
                                                        size="sm"
                                                        onClick={this.openConfirmation(_id)}
                                                    >
                                                        <i className="tim-icons icon-simple-remove" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-delete-${_id}`}
                                                    >
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan="10">There's no company in the database.</td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                                {companies?.length > 0 && 
                                    <PaginationComponent
                                        pages={pages}
                                        current_page={current_page}
                                        start_page={start_page}
                                        end_page={end_page}
                                        goToPage={this.goToPage}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ConfirmationModal
                    ref={el => this.deleteConfirmationModal = el}
                    title="Delete Company?"
                    buttons={[
                        { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                        { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteUser }
                    ]}
                >
                    Are you sure you want to delete this company?
                </ConfirmationModal>
            </div>
        )
    }
}

export default Companies