import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
	Input,
	FormGroup
} from "reactstrap";
import moment from "moment";
import Async from 'react-select/async';
// Components
import PaginationComponent from "components/Custom/Pagination";
// Helpers
import { calcStartEndPage } from 'helpers/pagination';
import { formatPrice } from "helpers/pricing";
import { formatTransactionStatus } from "helpers/status";
import { formatTransactionType } from "helpers/transaction";
// Requests
import { getCompaniesRequest } from "api/company";
import { getTransactionsRequest } from 'api/transaction';

class Transactions extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			transactions: null,
			page: 0,
			total: 0,
			limit: 25,
			pages: 0,
			current_page: 0,
			start_page: 0,
			end_page: 9,
			first_page: 0,
			last_page: 0,
			search: '',
			typeFilter: '',
			start_date: '',
			end_date: '',
			status: '',
			company: '',
			client: ''
		}
		this.timeout = null;
	}

	async componentDidMount() {
		await this.getTransactions();
	}

	getTransactions = async () => {
		const { limit, page, search, typeFilter, start_date, end_date, status, sellerCompany, clientCompany } = this.state;
		const transactionsObj = await getTransactionsRequest(page, limit, search, typeFilter, start_date && start_date.toISOString(), end_date && end_date.toISOString(), status, sellerCompany && sellerCompany.value || '', clientCompany && clientCompany.value || '');
		const transactions = transactionsObj.transactions;
		const total = transactionsObj.total;
		const pages = Math.ceil(total / limit);
		const last_page = pages - 1;
		const end_page = pages > 10 ? 9 : pages - 1;

		this.setState({
			transactions,
			total,
			pages,
			last_page,
			end_page
		})
	}

	goTo = path => this.props.history.push(path);

	goToPage = async (page) => {
		const { limit, pages, search } = this.state;
		const transactionsObj = await getTransactionsRequest(page, limit, search);
		const transactions = transactionsObj.transactions;
		const { start_page, end_page } = calcStartEndPage(page, pages);

		this.setState({
			transactions,
			current_page: page,
			start_page: start_page,
			end_page: end_page
		})
	}

	onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getTransactions();
		}, 300);
	}
	
	onChangeType = (event) => {
		const { value } = event.target;
		this.setState({
			typeFilter: value
		}, this.getTransactions);
	}

	onChangeDateFilter = (key) => (date) => {
		if(key == 'end_date') {
			date = date.endOf('day');
		}
		this.setState({
			[key]: date
		}, () => {
			if(this.state.start_date && this.state.end_date) {
				this.getTransactions();
			}
		});
	}
	
	isValidStartDate = (current) => {
		return current < moment();
	}

	isValidEndDate = (current) => {
		if(this.state.start_date) {
			return current < moment() && current.diff(this.state.start_date, 'days') > 0;
		} else {
			return current < moment();
		}
	}

	loadCompanies = (role) => async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 0, text, role, '');
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

	onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}

	render() {

		const {
			transactions,
			pages,
			current_page,
			start_page,
			end_page,
			status,
			sellerCompany,
			clientCompany,
			search,
			typeFilter,
			start_date,
			end_date
		} = this.state;

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Transactions</CardTitle>
									{/*<Row>
										<Col sm="6">
											<FormGroup>
												<Label>Start Date</Label>
												<Datetime
													inputProps={{
														className: "form-control",
														placeholder: "Start Date",
														name: "start_date"
													}}
													timeFormat={false}
													value={start_date}
													onChange={this.onChangeDateFilter('start_date')}
													isValidDate={this.isValidStartDate}
													closeOnSelect={true}
													dateFormat="DD/MM/YYYY"
												/>
											</FormGroup>
										</Col>
										<Col sm="6">
											<FormGroup>
												<Label>End Date</Label>
												<Datetime
													inputProps={{
														className: "form-control",
														placeholder: "End Date",
														name: "end_date"
													}}
													timeFormat={false}
													value={end_date}
													onChange={this.onChangeDateFilter('end_date')}
													isValidDate={this.isValidEndDate}
													closeOnSelect={true}
													dateFormat="DD/MM/YYYY"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Input placeholder="Pesquisar..." value={search} type="text" name="search" onChange={this.onChangeFilter}></Input>
									<Input className="mt-2" value={typeFilter} type="select" onChange={this.onChangeType}>
										<option value="">Select Type...</option>
										{
											transactionTypes.map(type => {
												return <option key={type} value={type}>{formatTransactionType(type)}</option>
											})
										}
									</Input>*/}
									<FormGroup>
										<Input
											type="select"
											name="status"
											value={status}
											onChange={this.onChangeFilter}
										>
											<option value={''}>-- SELECT STATUS --</option>
											<option value='created'>Created</option>
											<option value='pending'>Pending</option>
											<option value='canceled'>Canceled</option>
											<option value='confirmed'>Confirmed</option>
										</Input>
									</FormGroup>
									<FormGroup>
										<Input
											type="select"
											name="search"
											value={search}
											onChange={this.onChangeFilter}
										>
											<option value=''>-- SELECT TYPE--</option>
											<option value="order">Order</option>
											<option value="sale">Sale</option>
											<option value="taxes">Taxes</option>
											<option value="otherFees">Other Fees</option>
											<option value="deliveryFee">Delivery Fee</option>
											<option value="driverFee">Driver Fee</option>
										</Input>
									</FormGroup>	
									<FormGroup>
										<Async
											value={clientCompany}
											name="clientCompany"
											options={[]}
											loadOptions={this.loadCompanies('client')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('clientCompany')}
											isClearable
											placeholder="Select Client Company..."
										/>
									</FormGroup>
									<FormGroup>
										<Async
											value={sellerCompany}
											name="sellerCompany"
											options={[]}
											loadOptions={this.loadCompanies('seller')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('sellerCompany')}
											isClearable
											placeholder="Select Seller Company..."
										/>
									</FormGroup>
								</CardHeader>
								<CardBody>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th>Date</th>
												<th>Type</th>
												<th>Value</th>
												<th>Status</th>
												<th>Client</th>
												<th>Seller</th>
                                                <th>Order</th>
												<th className="text-right">Actions</th>
											</tr>
										</thead>
										<tbody>
											{transactions && transactions.length
												? transactions.map((transaction) => {
													return (
														<tr key={transaction._id}>
                                                            <td>{moment(transaction.createdAt).format('DD/MM/YYYY HH:mm')}</td>
															<td>{transaction.type ? formatTransactionType(transaction.type) : "N/A"}</td>
															<td>{transaction.amount ? <>
																<span className={transaction.isInbound === true ? 'text-success' : transaction.isInbound === false ? 'text-danger' : ''}>{transaction.isInbound === true ? '+' : transaction.isInbound === false ? '-' : ''} {`$ ${formatPrice(transaction.amount)}`}</span>
															</> : "N/A"}</td>
															<td>{transaction.status ? formatTransactionStatus(transaction.status) : "N/A"}</td>
															<td>{transaction.clientCompany ? <Link to={`/company?id=${transaction.clientCompany._id}`}>{transaction.clientCompany.name}</Link> : "N/A"}</td>
															<td>{transaction.sellerCompany ? <Link to={`/company?id=${transaction.sellerCompany._id}`}>{transaction.sellerCompany.name}</Link> : "N/A"}</td>
                                                            <td>{transaction.order ? <Link to={`/order?id=${transaction.order._id}`}>{transaction.order._id}</Link> : "N/A"}</td>
															<td className="text-right">
																<Button
																	className="btn-link btn-icon"
																	color="success"
																	id={`tooltip-view-${transaction._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/transaction?id=${transaction._id}`)}
																>
																	<i className="tim-icons icon-zoom-split" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-view-${transaction._id}`}
																>
																	View
                                                                </UncontrolledTooltip>
															</td>
														</tr>
													)
												})
												: <tr>
													<td colSpan="7">There are no transactions to show</td>
												</tr>
											}
										</tbody>
									</Table>
									{transactions?.length > 0 &&
										<PaginationComponent
											pages={pages}
											current_page={current_page}
											start_page={start_page}
											end_page={end_page}
											goToPage={this.goToPage}
										/>
                    }
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default Transactions;
