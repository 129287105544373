export const colors = [
    {
        "color": "White",
        "hex": "#FFFFFF"
    },
    {
        "color": "Silver",
        "hex": "#C0C0C0"
    },
    {
        "color": "Gray",
        "hex": "#808080"
    },
    {
        "color": "Black",
        "hex": "#000000"
    },
    {
        "color": "Red",
        "hex": "#FF0000"
    },
    {
        "color": "Maroon",
        "hex": "#800000"
    },
    {
        "color": "Yellow",
        "hex": "#FFFF00"
    },
    {
        "color": "Olive",
        "hex": "#808000"
    },
    {
        "color": "Lime",
        "hex": "#00FF00"
    },
    {
        "color": "Green",
        "hex": "#008000"
    },
    {
        "color": "Acqua",
        "hex": "#00FFFF"
    },
    {
        "color": "Teal",
        "hex": "#008080"
    },
    {
        "color": "Blue",
        "hex": "#0000FF"
    },
    {
        "color": "Navy",
        "hex": "#000080"
    },
    {
        "color": "Fuchsia",
        "hex": "#FF00FF"
    },
    {
        "color": "Purple",
        "hex": "#800080"
    }
]