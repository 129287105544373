import { apiRequest } from './index'

export const getApprovalsRequest = () => {
	return apiRequest(
		`/admin/approvals`,
		{
			method: 'GET'
		},
		true
	)
}

export const handleDriverApprovalRequest = (data) => {
	return apiRequest(
		`/admin/approvals/driver`,
		{
			method: 'POST',
			body: JSON.stringify(data)
		},
		true
	)
}

export const handleWithdrawalApprovalRequest = (data) => {
	return apiRequest(
		`/admin/approvals/withdrawal`,
		{
			method: 'POST',
			body: JSON.stringify(data)
		},
		true
	)
}

export const handleProductApprovalRequest = (data) => {
	return apiRequest(
		`/admin/approvals/product`,
		{
			method: 'POST',
			body: JSON.stringify(data)
		},
		true
	)
}