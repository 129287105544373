import React from "react"
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap"
import moment from "moment";

import { formatRole } from "helpers/strings";
import { getCompanyRequest } from "api/company";
import { updateCompanyRequest } from "api/company";
import states from '../../constants/states.json'

class EditCompany extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            company: null,
            btnLoading: false,
            error: null
        }
    }

    componentDidMount() {
        this.getCompany()
    }

    async getCompany() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        if (!id) {
            this.goTo('/company')
            return
        }

        try {
            const company = await getCompanyRequest(id);
            this.setState({
                company
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching user'
            });
        }
    }

    goTo = path => this.props.history.push(path)

    onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;

		this.setState(prevState => ({
			company: {
				...prevState.company,
				[name]: value,
			},
		}));
	}

    save = async () => {
		const { company } = this.state;

        this.setState({btnLoading: true})
		
		try {
            await updateCompanyRequest(company)

			this.goTo('/company');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving user'
			})
		}

        this.setState({btnLoading: false})
	}

    render () {
        const {
            company,
            btnLoading,
            error
        } = this.state

        const {
            name,
            role,
            owner,
            address,
            createdAt,
            updatedAt
        } = company || {}

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">Edit Company</CardTitle>
                                    <Button
                                        color="default"
                                        onClick={() => this.goTo('/company')}
                                    >
                                        Back
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    {!company 
                                        ? <div>Loading...</div>
                                        : <>
                                            <FormGroup>
												<Label>Name</Label>
												<Input
													type="text"
													name="name"
													value={name || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Role</Label>
												<Input
													type="text"
													name="role"
													value={formatRole(role) || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Owner Email</Label>
												<Input
													type="text"
													name="owner.email"
													value={owner?.email || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
                                            {
                                                role === 'seller' && <>
													<FormGroup>
														<Label>Company Street Address</Label>
														<Input
															type="text"
															name="street"
															value={address?.streetAddress || ''}
															onChange={this.onChangeField}
                                                            disabled
														/>
													</FormGroup>

													<FormGroup>
														<Label>Company Street Notes</Label>
														<Input
															type="text"
															name="notes"
															value={address?.notes || ''}
															onChange={this.onChangeField}
                                                            disabled
														/>
													</FormGroup>
													<FormGroup>
														<Label>Company City</Label>
														<Input
															type="text"
															name="city"
															value={address?.city || ''}
															onChange={this.onChangeField}
                                                            disabled
														/>
													</FormGroup>
													<FormGroup>
														<Label>Company State</Label>
														<Input
															type="select"
															name="state"
															value={address?.state || ''}
															onChange={this.onChangeField}
                                                            disabled
														>
															<option value=''>-- SELECT --</option>
															{states.map(state => (
																<option value={state.value}>{state.name}</option>
															))}
														</Input>
													</FormGroup>
													<FormGroup>
														<Label>Company Zip Code</Label>
														<Input
															type="text"
															name="zipCode"
															value={address?.zipCode || ''}
															onChange={this.onChangeField}
                                                            disabled
														/>
													</FormGroup>
												</> 
                                            }
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Created At</Label>
                                                        <Input
                                                            type="text"
                                                            name="createdAt"
                                                            value={moment(createdAt).format('MM/DD/YYYY hh:mm:ss A') || ''}
                                                            onChange={this.onChangeField}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Updated At</Label>
                                                        <Input
                                                            type="text"
                                                            name="updatedAt"
                                                            value={moment(updatedAt).format('MM/DD/YYYY HH:mm:ss A') || ''}
                                                            onChange={this.onChangeField}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {error 
                                                && <div className="alert alert-danger mt-3">
                                                    <span>{error}</span>
                                                </div> 
                                            }
                                            <Button
                                                color="primary"
                                                disabled={btnLoading}
                                                onClick={this.save}
                                            >
                                                {btnLoading ? "Processing..." : "Save Changes"}
                                            </Button>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default EditCompany