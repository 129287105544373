/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import { FormGroup, Input, Row, Col } from "reactstrap";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step3Select: null
    };
  }
  render() {

    const { reportType } = this.props;

    return (
      <>
        {reportType !== 'productlist' && reportType !== 'transactions' ? <form>
          <Row className="justify-content-center">
            <Col sm="5">
              <FormGroup>
                <label>From</label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here"
                  }}
                  timeFormat={false}
                />
              </FormGroup>
            </Col>
            <Col sm="5">
              <FormGroup>
                <label>To</label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here"
                  }}
                  timeFormat={false}
                />
              </FormGroup>
            </Col>
          </Row>
        </form> : 'This report type does not support any period options'}
      </>
    );
  }
}

export default Reports;
