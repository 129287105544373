export const calcStartEndPage = (page, pages) => {
    let start_page, end_page;
    if (pages > 10) {
        if (page - 5 < 0) {
            start_page = 0;
        } else if (page + 5 >= pages) {
            start_page = pages - 10;
        } else {
            start_page = page - 5;
        }
    
        if (page - 5 < 0) {
            end_page = 9;
        } else if (page + 5 >= pages) {
            end_page = pages - 1;
        } else {
            end_page = page + 4;
        }
    } else {
        start_page = 0;
        end_page = pages - 1;
    }    

    return {
        start_page: start_page,
        end_page: end_page
    }
}