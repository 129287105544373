import { apiRequest } from "api";

export const createCouponRequest = (coupon) => {
  return apiRequest(
    `/admin/coupons`,
    {
      method: "POST",
      body: JSON.stringify(coupon),
    },
    true
  );
};

export const updateCouponRequest = (coupon) => {
  return apiRequest(
    `/admin/coupons/${coupon._id}`,
    {
      method: "PUT",
      body: JSON.stringify(coupon),
    },
    true
  );
};

export const getCouponsRequest = (page, limit, term) => {
  return apiRequest(
    `/admin/coupons?page=${page}&limit=${limit}&search=${term}`,
    {
      method: "GET",
    },
    true
  );
};

export const getCouponRequest = (couponId) => {
  return apiRequest(
    `/admin/coupons/${couponId}`,
    {
      method: "GET",
    },
    true
  );
};

export const deleteCouponRequest = (couponId) => {
  return apiRequest(
    `/admin/coupons/${couponId}`,
    {
      method: "DELETE",
    },
    true
  );
};
