import { apiRequest } from ".";

export const handleProductsCSVRequest = (data) => {
	return apiRequest(
		'/admin/products/import',
		{
			method: 'POST',
			body: JSON.stringify(data)
		}
	)
}
