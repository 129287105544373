import React from 'react';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { title, children, buttons, backdrop = false } = this.props;

    return (
      <Modal backdrop={backdrop} isOpen={this.state.modal} toggle={this.toggle}
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        marginTop: '0',
        width: '80%',
        height: '80%'

      }}
      >
        <ModalHeader toggle={this.toggle}>{title || 'Modal'}</ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col style={{ padding: '10px' }}>{children}</Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter className="justify-content-end">
					{buttons.map((button, index) => (
						<button
							key={index}
							className={`m-2 ${button.className || ''}`}
							onClick={() => {
								const { toggle = true } = button;
								toggle && this.toggle();
								if (button.onClick) {
									button.onClick();
								}
							}}
						>
							{button.title}
						</button>
					))}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
