import { apiRequest} from './index'

export const getTransactionsRequest = (page, limit, search, type, start_date, end_date, status, sellerCompany, clientCompany) => {
  return apiRequest(
    `/admin/transactions?page=${page}&limit=${limit}&search=${search}&type=${type}&start_date=${start_date}&end_date=${end_date}&status=${status}&sellerCompany=${sellerCompany}&clientCompany=${clientCompany}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getTransactionByIdRequest = (id) => {
  return apiRequest(
    `/admin/transactions/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getTransactionsStatsRequest = () => {
  return apiRequest(
    `/admin/transaction-stats`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCorporationTransactionsStatsRequest = (id) => {
  return apiRequest(
    `/admin/corporation-transaction-stats/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCompanyTransactionsStatsRequest = (id) => {
  return apiRequest(
    `/admin/company-transaction-stats/${id}`,
    {
      method: 'GET'
    },
    true
  )
}