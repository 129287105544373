import React from "react";
// reactstrap components
import { FormGroup, Row, Col, Button } from "reactstrap";
import Select from "react-select";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importType: "",
    };
  }
  /*
  // function that returns true if value is email, false otherwise
  verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };*/
  isValidated = () => {
    /*if (
      this.state.firstnameState === "has-success" &&
      this.state.lastnameState === "has-success" &&
      this.state.emailState === "has-success" &&
      this.state.phoneState === "has-success"
    ) {
      return true;
    } else {
      if (this.state.firstnameState !== "has-success") {
        this.setState({ firstnameState: "has-danger" });
      }
      if (this.state.lastnameState !== "has-success") {
        this.setState({ lastnameState: "has-danger" });
      }
      if (this.state.emailState !== "has-success") {
        this.setState({ emailState: "has-danger" });
      }
      if (this.state.phoneState !== "has-success") {
        this.setState({ phoneState: "has-danger" });
      }
      return false;
    }*/
    return true;
  };
  render() {
    return (
      <>
        <h5 className="info-text">Let's start with the basic information</h5>
        <Row className="justify-content-center mt-5">
          <Col sm="10">
            <FormGroup>
              <label>Import type</label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name=""
                onChange={(value) => {
                  this.props.onChangeImportType &&
                    this.props.onChangeImportType(value);
                  this.setState({ step3Select: value });
                }}
                value={this.state.step3Select}
                options={[{ value: "products", label: "Products" }]}
                placeholder="Select the import type"
              />
            </FormGroup>
            <Row className="d-flex justify-content-md-end">
              <Button onClick={this.props.nextStep}>Next Step</Button>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Reports;
