import React from "react";
// react plugin used to create a form with multiple steps
// import ReactWizard from "react-bootstrap-wizard";

// reactstrap components
import { Col } from "reactstrap";

// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import { generateProductReport, generateTransactionsReport } from "api/reports.js";

class Reports extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reportType: null,
      isReportGenerated: false
    };
  }

  onChangeReportType = (reportType) => {
    this.setState({ reportType: reportType.value });
  }

  onGenerateReport = async () => {
    const { reportType } = this.state;
    switch(reportType) {
      case 'productlist': 
        try {
          const resp = await generateProductReport();
          var blob = new Blob([resp], {type: 'application/vnd.ms-excel'});
          var downloadUrl = URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "product.xls";
          document.body.appendChild(a);
          a.click();
          this.setState({
            isReportGenerated: true
          });
          const container = document.querySelector('.main-panel');
          if(container) {
            container.scrollTop = 0;
          }
          document.body.removeChild(a);
          break;
        } catch(err) {
          console.log(err);
        }
      case 'transactions': 
        try {
          const resp = await generateTransactionsReport();
          var blob = new Blob([resp], {type: 'application/vnd.ms-excel'});
          var downloadUrl = URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "transactions.xls";
          document.body.appendChild(a);
          a.click();
          this.setState({
            isReportGenerated: true
          });
          const container = document.querySelector('.main-panel');
          if(container) {
            container.scrollTop = 0;
          }
          document.body.removeChild(a);
          break;
        } catch(err) {
          console.log(err);
        }
      default: break;
    }
  }

  render() {

    const { isReportGenerated, reportType } = this.state;

    const steps = [
      {
        stepName: "Type",
        stepIcon: "tim-icons icon-single-copy-04",
        component: Step1,
        stepProps: {
          onChangeReportType: this.onChangeReportType
        }
      },
      {
        stepName: "Filters",
        stepIcon: "tim-icons icon-zoom-split",
        component: Step2,
        stepProps: {
          reportType: reportType
        }
      },
      {
        stepName: "Period",
        stepIcon: "tim-icons icon-calendar-60",
        component: Step3,
        stepProps: {
          reportType: reportType
        }
      }
    ];

    return (
      <>
        <div className="content">
          <Col className="mr-auto ml-auto" md="10">
            {/*!isReportGenerated ? <ReactWizard
              steps={steps}
              navSteps
              validate
              title="Generate a Report"
              description="Select report type and filters."
              headerTextCenter
              finishButtonClasses="btn-wd btn-info"
              nextButtonClasses="btn-wd btn-info"
              previousButtonClasses="btn-wd"
              progressbar
              color="blue"
              finishButtonClick={this.onGenerateReport}
            /> : <div>
              <h3 className="card-title">Report has been downloaded successfully.</h3>
              <button onClick={() => this.setState({ isReportGenerated: false })} className="btn btn-wd text-center btn-info">Generate Another Report</button>
            </div>*/}
          </Col>
        </div>
      </>
    );
  }
}

export default Reports;
