import React from "react"
import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap"
import moment from "moment";
import Async from 'react-select/async';
import { Link } from "react-router-dom";
// Helpers
import { formatRole } from "helpers/strings";
// Requests
import { getCorporationRequest, updateCorporationRequest } from "api/corporation";
import { getCompaniesRequest, updateCompanyRequest } from "api/company";

class EditCorporation extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            corporation: null,
            stores: null,
            btnLoading: false,
            error: null,
            selectedCompany: null
        }
    }

    componentDidMount() {
        this.getCorporation()
        this.getCorporationStores()
    }

    async getCorporation() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        if (!id) {
            this.goTo('/corporation')
            return
        }

        try {
            const corporation = await getCorporationRequest(id);
            this.setState({
                corporation
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching user'
            });
        }
    }

    async getCorporationStores() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        try {
            const storesResponse = await getCompaniesRequest(0, 0, '', '', id);
            this.setState({
                stores: storesResponse.companies
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching stores'
            });
        }
    }

    goTo = path => this.props.history.push(path)

    onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;

		this.setState(prevState => ({
			corporation: {
				...prevState.corporation,
				[name]: value,
			},
		}));
	}

    onSelectChange = (key) => (value) => {

        this.setState({
            [key]: value
        })
	}

    loadCompanies = (role) => async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 0, text, role, null);
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

    save = async () => {
		const { corporation } = this.state;

        this.setState({btnLoading: true})
		
		try {
            await updateCorporationRequest(corporation)

			this.goTo('/corporations');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving user'
			})
		}

        this.setState({btnLoading: false})
	}

    linkStore = async () => {
		const { corporation, selectedCompany } = this.state;

        this.setState({btnLoading: true})
		
		try {
            await updateCompanyRequest({
                _id: selectedCompany.data._id,
                corporation: corporation._id
            })

			this.setState({
              selectedCompany: null  
            })

            this.getCorporationStores()
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving user'
			})
		}

        this.setState({btnLoading: false})
	}

    render () {
        const {
            corporation,
            btnLoading,
            error,
            selectedCompany,
            stores
        } = this.state;

        console.log('selectedCompany', JSON.stringify(selectedCompany))

        const {
            name,
            role,
            owner,
            ownerFirstName,
            ownerLastName,
            ownerPhoneNumber,
            createdAt,
            updatedAt
        } = corporation || {}

        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">Edit Corporation</CardTitle>
                                    <Button
                                        color="default"
                                        onClick={() => this.goTo('/corporation')}
                                    >
                                        Back
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    {!corporation 
                                        ? <div>Loading...</div>
                                        : <>
                                            <FormGroup>
												<Label>Name</Label>
												<Input
													type="text"
													name="name"
													value={name || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Role</Label>
												<Input
													type="text"
													name="role"
													value={formatRole(role) || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Owner First Name</Label>
												<Input
													type="text"
													name="ownerFirstName"
													value={ownerFirstName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Owner Last Name</Label>
												<Input
													type="text"
													name="ownerLastName"
													value={ownerLastName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Owner Phone Number</Label>
												<Input
													type="text"
													name="ownerPhoneNumber"
													value={ownerPhoneNumber || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
                                            <FormGroup>
												<Label>Owner Email</Label>
												<Input
													type="text"
													name="owner.email"
													value={owner?.email || ''}
													onChange={this.onChangeField}
                                                    disabled
												/>
											</FormGroup>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Created At</Label>
                                                        <Input
                                                            type="text"
                                                            name="createdAt"
                                                            value={moment(createdAt).format('MM/DD/YYYY hh:mm:ss A') || ''}
                                                            onChange={this.onChangeField}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label>Updated At</Label>
                                                        <Input
                                                            type="text"
                                                            name="updatedAt"
                                                            value={moment(updatedAt).format('MM/DD/YYYY HH:mm:ss A') || ''}
                                                            onChange={this.onChangeField}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {error 
                                                && <div className="alert alert-danger mt-3">
                                                    <span>{error}</span>
                                                </div> 
                                            }
                                            <Button
                                                color="primary"
                                                disabled={btnLoading}
                                                onClick={this.save}
                                            >
                                                {btnLoading ? "Processing..." : "Save Changes"}
                                            </Button>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Stores
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                {!corporation || !stores
                                    ? <div>Loading...</div>
                                    :   <>
                                            {stores.map((store) => {
                                                return (
                                                    <div className="typography-line">
                                                        <h4>
                                                            <span>Name</span>
                                                            <Link to={`/company?id=${store._id}`}>{store.name}</Link>
                                                        </h4>
                                                    </div>
                                                )
                                            })}   
                                            <h3>Link new store</h3> 
                                            <FormGroup>
                                                <Async
                                                    value={selectedCompany}
                                                    name={"selectedCompany"}
                                                    options={[]}
                                                    loadOptions={this.loadCompanies(role)}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={this.onSelectChange("selectedCompany")}
                                                    isClearable
                                                    placeholder="Select Store..."
                                                />
                                            </FormGroup>
                                            {error 
                                                && <div className="alert alert-danger mt-3">
                                                    <span>{error}</span>
                                                </div> 
                                            }
                                            <Button
                                                color="primary"
                                                disabled={btnLoading}
                                                onClick={this.linkStore}
                                            >
                                                {btnLoading ? "Processing..." : "Save Changes"}
                                            </Button>
                                        </>
                                }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default EditCorporation