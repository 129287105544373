import React, { PureComponent } from 'react';
import moment from 'moment';
import {
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Row,
	Col
} from "reactstrap";
// Requests

// Helpers
import { formatPrice } from "../helpers/pricing";
// Requests
import { getTransactionsStatsRequest } from '../api/transaction';

export default class Dashboard extends PureComponent {
	
	constructor(props) {
		super(props);
		this.state = {
			financialStats: null
		}
	}

	componentDidMount = async () => {
		await this.getStats();
	}

	getStats = async () => {
		const stats = await getTransactionsStatsRequest();
		this.setState({
			financialStats: stats
		})
	}

	isValidStartDate = (current) => {
		return current < moment() && this.state.salesEndDate.diff(current, 'days') <= 30;
	}

	isValidEndDate = (current) => {
		return current < moment() && current.diff(this.state.salesStartDate, 'days') > 0;
	}

	render() {

		const {
			financialStats
		} = this.state;

	  	const totalRevenue = financialStats ? financialStats.totalRevenue : 0;
		const totalSales = financialStats ? financialStats.totalSales : 0;
		const totalTaxes = financialStats ? financialStats.totalTaxes : 0;
		const totalDeliveryFees = financialStats ? financialStats.totalDeliveryFees : 0;
		const totalDriverFees = financialStats ? financialStats.totalDriverFees : 0;
		const totalOtherFees = financialStats ? financialStats.totalOtherFees : 0;
		const totalDiscounts = financialStats ? financialStats.totalDiscounts : 0;
		const totalProfit = totalDeliveryFees - totalDriverFees - totalDiscounts;
		return (
			<div className="content">
					<Row>
						<Col className="ml-auto" sm="12">
							<Card className="card-chart">
								<CardHeader>
									<h3 className="card-category mb-3">Financial Report</h3>
									<Row>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Total Revenue</p>
																<CardTitle tag="h3">$ {formatPrice(totalRevenue)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Products</p>
																<CardTitle tag="h3">$ {formatPrice(totalSales)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Taxes</p>
																<CardTitle tag="h3">$ {formatPrice(totalTaxes)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Row>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Stripe Fees Collected</p>
																<CardTitle tag="h3">$ {formatPrice(totalOtherFees)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Delivery Fees Collected</p>
																<CardTitle tag="h3">$ {formatPrice(totalDeliveryFees)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col lg="4" md="4">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Delivery Fees Paid</p>
																<CardTitle tag="h3">$ {formatPrice(totalDriverFees)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Row>	
										<Col lg="4" md="4" className="ml-auto">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Coupon Discounts</p>
																<CardTitle tag="h3">$ {formatPrice(totalDiscounts)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
										<Col lg="4" md="4" className="ml-auto">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Total Profit</p>
																<CardTitle tag="h3">$ {formatPrice(totalProfit)}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					</Row>
				</div>
				)
    }
}