import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col
} from "reactstrap";
import moment from 'moment';
// Helpers
import productPlaceholder from 'assets/img/product-placeholder.jpg';
// Requests
import { getTransferByIdRequest } from "api/transfer";

class TransferDetails extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			transfer: null
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			const transfer = await getTransferByIdRequest(id);
			this.setState({ transfer })
		} else {
			this.goTo('/transfers')
		}
	}

	goTo = path => this.props.history.push(path);

	render() {

		const {
			transfer
		} = this.state;

		const {
			_id,
            corporation,
            originCompany,
            destinationCompany,
            products,
			miscellaneousProducts,
            deliveryFee,
            status,
			createdAt,
			updatedAt
		} = transfer || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Transfer Details
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/transfers')}
									>
										Back
                  					</Button>
								</CardHeader>
								<CardBody>
									{!transfer
										? <div>Loading...</div>
										: <>
											<div className="typography-line">
												<h4>
													<span>ID</span>
													{_id}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Corporation</span>
													{corporation.name}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Origin</span>
													{originCompany.name}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Destination</span>
													{destinationCompany.name}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Status</span>
													{status}
												</h4>
											</div>
                                            <div className="typography-line">
												<h4>
													<span>Delivery Fee</span>
													$ {deliveryFee}
												</h4>
											</div>
                                            <Card className='detail-card p-2'>
                                                <CardHeader>
                                                    <h3>App Products</h3>
                                                </CardHeader>
                                                <CardBody>
                                                    {
                                                        products && products.length 
														? products.map(({product, quantity}) => (
                                                            <tr className="d-flex align-items-center my-1">
                                                            <div className="my-1">
                                                            {product.images && product.images.length > 0
                                                                ? <a href={product.images[0]} target="_blank"><img className="product-preview" src={product.images[0]} /></a> 
                                                                : <img className="product-preview" src={productPlaceholder} />
                                                            }
                                                            </div>
                                                            <p className="mx-4">{product?.name}</p>
                                                            <p className="ml-auto">{quantity}</p>
                                                        </tr>
                                                        ))
														: null
                                                    }
                                                </CardBody>
                                            </Card>
											<div className="typography-line">
												<h4>
													<span>Non-App Products</span>
													{miscellaneousProducts}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Created At</span>
													{createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
											<div className="typography-line">
												<h4>
													<span>Updated At</span>
													{updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
												</h4>
											</div>
										</>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default TransferDetails;
