import { apiRequest} from './index'

export const getRunsRequest = (page, limit, search, status='') => {
  return apiRequest(
    `/admin/runs?page=${page}&limit=${limit}&term=${search}&status=${status}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getRunRequest = (id) => {
  return apiRequest(
    `/admin/runs/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getRunsStatsRequest = () => {
    return apiRequest(
      `/admin/run-stats`,
      {
        method: 'GET'
      },
      true
    )
  }
  