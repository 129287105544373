/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

class Reports extends React.Component {
  render() {

    const { reportType } = this.props;
    
    return (
      <>
        <h5 className="info-text">Filters</h5>
        <Row className="justify-content-center">
          <Col className="text-center" lg="10">
            {reportType !== 'productlist' && reportType !== 'transactions' ? <p>Filters here</p> : <p>This report does not support any filter options</p>}
          </Col>
        </Row>
      </>
    );
  }
}

export default Reports;
