import { apiRequest } from './index';
import { getToken } from "helpers/auth"

export const saveImageFileRequest = async (product_id, file) => {
	const formData = new FormData();
	formData.append('file', file);

	const end_point = process.env.REACT_APP_API_ENDPOINT + '/api/admin/products/upload-image/' + product_id;

	const response = await fetch(end_point, {
		method: 'POST',
		body: formData,
		headers: {
			"auth-token": getToken()
		}
	})

	const data = await response.json()
	return data;
}

export const saveImagesArrayRequest = (product_id, data) => {
	return apiRequest(
		'/admin/products/save-image-array/' + product_id,
		{
			method: 'POST',
			body: JSON.stringify(data)
		}
	)
}

export const deleteImagesArrayRequest = (product_id, data) => {
	return apiRequest(
		'/admin/products/delete-from-bucket/' + product_id,
		{
			method: 'DELETE',
			body: JSON.stringify(data)
		}
	)
}