import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';
// Components
import ImageCard from 'components/ImageCard/ImageCard';
// Helpers
import { formatProductStatus } from "helpers/status";
import { getCategoryNameById } from "helpers/category";
// Requests
import { getProductRequest } from 'api/product';

class ProductDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          product: null
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');

        if (id) {
            const product = await getProductRequest(id);
            this.setState({ product })
        } else {
            this.goTo('/products')
        }
    }
  
    goTo = path => this.props.history.push(path);

    render() {

        const {
            product={}
        } = this.state;

        const {
          _id,
          name, 
          description,
          status, 
          images=[],
          categoryLevelOne,
          categoryLevelTwo,
          specifications,
          relevance,
          sku,
          size,
          color,
          createdAt,
          updatedAt
        } = product || {};

        return (
            <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader className="mb-5">
                      <CardTitle tag="h3">
                        Product Details
                      </CardTitle>
                      <Button 
                        className="float-right"
                        color="default"
                        onClick={() => this.goTo('/products')}
                      >
                        Back
                      </Button>
                    </CardHeader>
                    <CardBody>
                      {!product 
                      ? <div>Loading...</div>
                      : <>
                      <div className="typography-line">
                        <h4>
                          <span>ID</span>
                          {_id}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>SKU</span>
                          {sku || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Name</span>
                          {name}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Description</span>
                          {description || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Status</span>
                          {status ? formatProductStatus(status) : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Images</span>
                          {(images && images.length) > 0
                            ? images.map((image, index) => {
                              return (
                                <ImageCard
                                  key={index}
                                  src={image}
                                  id={"image" + index}
                                />
                              )
                            })
                            : 'N/A'}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Category Level 1</span>
                          {categoryLevelOne ? getCategoryNameById(categoryLevelOne) : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Category Level 2</span>
                          {categoryLevelTwo ? getCategoryNameById(categoryLevelTwo) : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Specifications</span>
                          {(specifications && Object.keys(specifications).map((spec, index) => {
                          return <Row key={index}>
                            <Col sm={6}><p>{spec}</p></Col>
                            <Col sm={6}><p>{specifications[spec]}</p></Col>
                          </Row>
                          })) || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Relevance</span>
                          {relevance || "N/A"}
                        </h4>
                      </div>
                      
                      <div className="typography-line">
                        <h4>
                          <span>Size</span>
                          {size || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Color</span>
                          {color || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Created At</span>
                          {createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                        </h4>
                      </div>
                      <div className="typography-line">
                        <h4>
                          <span>Updated At</span>
                          {updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                        </h4>
                      </div>
                      </>
                      }
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
    }
}

export default ProductDetails;
