// a size model in the format { size, value }
export const sizes = [
	{
		size: "Standard",
		value: "standard"
	},
	{
		size: "Small",
		value: "small"
	},
	{
		size: "Medium",
		value: "medium"
	},
	{
		size: "Large",
		value: "large"
	}
]