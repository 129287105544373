import moment from "moment";

export const clearCredentials = () => {
  localStorage.removeItem("userCredentials");
};

export const setCredentials = auth => {
  localStorage.setItem('userCredentials', JSON.stringify({...auth, expires: moment().add(auth.token_life, 'seconds').toDate() }))
}

export const getCredentials = () => {
  const credentials = JSON.parse(localStorage.getItem('userCredentials'));

  if (moment().diff(moment(credentials?.expires)) > 0) {
    clearCredentials()
    return;
  }
  
  return credentials
}

export const getToken = () => {
    const credentials = getCredentials();
    
    return credentials ? credentials.token : undefined;
};

  
