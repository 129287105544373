import moment from 'moment';

export const defaultFormat = "DD/MM/YY HH:mm";

export function isValidDateOfBirth(current) {
	return current < moment();
}

export const getFormattedDate = (date="") => {
    if(date) {
        return moment(date).format(defaultFormat)
    }
    return 'N/A';
}