import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Row,
  Col,
  Table,
  Input,
  UncontrolledTooltip,
  FormGroup
} from "reactstrap";
import PaginationComponent from "../../components/Custom/Pagination";
// import ReactBSAlert from "react-bootstrap-sweetalert";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import { getUsersRequest, deleteUserRequest, getUserStatsRequest } from '../../api/user';
import { calcStartEndPage } from '../../helpers/pagination';
import { formatRole } from '../../helpers/strings';
import Async from 'react-select/async';
import { getCompaniesRequest } from "api/company";


class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          users: null,
          alert: null,
          page: 0,
          total: 0,
          limit: 25,
          pages: 0,
          current_page: 0,
          start_page: 0,
          end_page: 9,
          first_page: 0,
          last_page: 0,
          search: "",
          deleteUserId: '',
          stats: null
        }
        this.timeout = null;
    }

    async componentDidMount() {
        await this.getUsers()
        await this.getStats();
    }

    getUsers = async () => { 
        try {
            const { limit, page, search, role, company } = this.state;     
            const usersObj = await getUsersRequest(page, limit, search, role, company && company.value || '');
            const users = usersObj.users;
            const total = usersObj.total;
            const pages = Math.ceil(total / limit);
            const last_page = pages - 1;
            const end_page = pages > 10 ? 9 : pages - 1;

            this.setState({
                users,
                total,
                pages,
                last_page,
                end_page
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    getStats = async () => {
        try {
            const stats = await getUserStatsRequest();
            this.setState({
                stats
            })
        } catch (error) {
            console.log('error', error)
        }
    }
  
    goTo = path => this.props.history.push(path);

    goToPage = async (page) => {
        const { limit, pages, search } = this.state;
        const usersObj = await getUsersRequest(page, limit, search);
        const users = usersObj.users;
        const { start_page, end_page } = calcStartEndPage(page, pages);

        this.setState({
            users: users,
            current_page: page,
            start_page: start_page,
            end_page: end_page
        })
    }

    /*hideAlert = () => {
        this.setState({
          alert: null
        });
    };*/

    /*showConfirmDeleteAlert = (id) => {
        this.setState({
            alert: (
              <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => this.deleteUser(id)}
                onCancel={() => this.hideAlert()}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
              >
                The user will be deleted permanently
              </ReactBSAlert>
            )
        });
    }*/

    /*deleteUser = async (id) => {
        
    }*/

    onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getUsers();
		}, 300);
    }

    openConfirmation = (userId) => () => {
		this.setState({
			deleteUserId: userId
		});
		this.deleteConfirmationModal.toggle();
    }
    
    deleteUser = async () => {
		const { deleteUserId } = this.state;
        await deleteUserRequest(deleteUserId);
        this.getUsers()
	}

    loadCompanies = async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 0, text, '', '');
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

    onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}

    render() {

        const {
            users,
            alert,
            pages,
            current_page,
            start_page,
            end_page,
            search,
            stats,
            role,
            company
        } = this.state;
        
        const totalClients = stats ? stats.clientCount : 0;
        const totalSellers = stats ? stats.sellerCount : 0;
        const totalDrivers = stats ? stats.driverCount : 0;

        return (
            <>
            <div className="content">
            {alert}
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardHeader>
                            <Row>
                                <Col lg="4" md="4">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-single-02" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total clients</p>
                                                <CardTitle tag="h3">{totalClients}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-single-02" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total sellers</p>
                                                <CardTitle tag="h3">{totalSellers}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="4" md="4">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-single-02" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total drivers</p>
                                                <CardTitle tag="h3">{totalDrivers}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
                <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle className="d-inline-block" tag="h4">
                            Users
                        </CardTitle>
                        <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo('/user/create')}>Add User</Button>
                        <FormGroup>
                            <Input placeholder="Search Users..." value={search} type="text" name="search" onChange={this.onChangeFilter}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="select"
                                name="role"
                                value={role || ''}
                                onChange={this.onChangeFilter}
                            >
                                <option value=''>-- SELECT TYPE--</option>
                                <option value="client">Client</option>
                                <option value="seller">Seller</option>
                                <option value="driver">Driver</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Async
                                value={company}
                                name="company"
                                options={[]}
                                loadOptions={this.loadCompanies}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={this.onSelectChange('company')}
                                isClearable
                                placeholder="Select Company..."
                            />
                        </FormGroup>
                    </CardHeader>
                    <CardBody>
                    <Table responsive>
                        <thead className="text-primary">
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Role</th>
                            <th>Company</th>
                            <th>Corporation</th>
                            <th className="text-right">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users && users.length
                        ? users.map((user => {
                            let userName = user.isCorporationAdmin ? `${user.corporation?.ownerFirstName} ${user.corporation?.ownerLastName}` : `${user[user.role]?.firstName} ${user[user.role]?.lastName}`;
                            let userRole;
                            if (user.isAdmin) {
                                userRole = "CR Admin";
                            } else if (user.isCorporationAdmin) {
                                userRole = "Corporation Owner";
                            } else if (user.isOwner) {
                                userRole = "Company Owner";
                            } else if (user.role !== "driver") {
                                userRole = "Company Employee";
                            } else {
                                userRole = "N/A"
                            }

                            return (
                                <tr key={user?._id}>
                                    <td>{user?.email}</td>
                                    <td>{userName}</td>
                                    <td>{formatRole(user.role)}</td>
                                    <td>{userRole}</td>
                                    <td>{user.company?.name || 'N/A'}</td>
                                    <td>{user.corporation?.name || 'N/A'}</td>
                                    <td className="text-right">
                                        <Button
                                            className="btn-link btn-icon"
                                            color="success"
                                            id={`tooltip-view-${user._id}`}
                                            size="sm"
                                            onClick={() => this.goTo(`/user?id=${user._id}`)}
                                        >
                                            <i className="tim-icons icon-zoom-split" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-view-${user._id}`}
                                        >
                                            View
                                        </UncontrolledTooltip>
                                        <Button
                                            className="btn-link btn-icon"
                                            color="info"
                                            id={`tooltip-edit-${user._id}`}
                                            size="sm"
                                            onClick={() => this.goTo(`/user/edit?id=${user._id}`)}
                                        >
                                            <i className="tim-icons icon-pencil" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-edit-${user._id}`}
                                        >
                                            Edit
                                        </UncontrolledTooltip>
                                        <Button
                                            className="btn-link btn-icon"
                                            color="danger"
                                            id={`tooltip-delete-${user._id}`}
                                            size="sm"
                                            onClick={this.openConfirmation(user._id)}
                                        >
                                            <i className="tim-icons icon-simple-remove" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-delete-${user._id}`}
                                        >
                                            Delete
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            )
                        } 
                        ))
                        :   <tr>
                                <td colSpan="10">There's no user in the database.</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    {users?.length > 0 && 
                        <PaginationComponent 
                            pages={pages}
                            current_page={current_page}
                            start_page={start_page}
                            end_page={end_page}
                            goToPage={this.goToPage}
                        />
                    }
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <ConfirmationModal
                ref={el => this.deleteConfirmationModal = el}
                title="Delete User?"
                buttons={[
                    { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                    { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteUser }
                ]}
            >
                Are you sure you want to delete this user?
            </ConfirmationModal>
            </div>
        </>
        );
    }
}

export default Users;
