import React from "react";

// reactstrap components
import { Row, Col, Button } from "reactstrap";

import ProductsSample from "../../../assets/xls/products-instructions.xlsx";

class Reports extends React.Component {
  render() {
    const { importType } = this.props;

    return (
      <>
        <h5 className="info-text">Format</h5>
        <Row className="justify-content-center">
          <Col className="text-center" lg="10">
            <p>
              Download the excel format sample bellow. Use it to insert all
              information.
            </p>
            <p>
              Download link{" "}
              <a
                href={
                  importType === "products" ? ProductsSample : ProductsSample
                }
                download
              >
                here
              </a>
            </p>
            <p>
              When finished, save the document in .csv format and upload it in
              the next step.
            </p>
            <Row className="d-flex justify-content-lg-between">
              <Button onClick={this.props.previousStep}>Previous Step</Button>
              <Button onClick={this.props.nextStep}>Next Step</Button>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default Reports;
