import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment'
// Requests
import { getCompanyRequest } from 'api/company'
import { getCompanyTransactionsStatsRequest } from 'api/transaction';
// Helpers
import { formatRole } from 'helpers/strings'
import { formatStateName } from 'helpers/state'
import { formatPrice } from "helpers/pricing";
// Components
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { editCompanyProductRequest, deleteCompanyProductRequest } from 'api/company';
// Assets
import productPlaceholder from 'assets/img/product-placeholder.jpg';
import ProductStoreFormModal from 'components/Modals/ProductStoreFormModal';
import { saveProductIntoCompanyRequest } from 'api/company';

class CompanyDetails extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            company: null,
            stats: null,
            error: null,
            products: null,
            deleteProductId: null,
            editProduct: null,
            isCreateModal: true,
            formModalIsOpen: false,
            formModalIsLoading: false,
            formModalError: ""
        }
    }

    componentDidMount() {
        this.getCompany()
        this.getCompanyStats()
    }

    async getCompany() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');


        if (!id) {
            this.goTo('/company')
            return
        }

        try {
            const company = await getCompanyRequest(id);
            console.log(company.products)
            this.setState({
                company
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching user'
            });
        }

    }

    async getCompanyStats() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        try {
            const stats = await getCompanyTransactionsStatsRequest(id);
            this.setState({
                stats
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching stores'
            });
        }
    }

    goTo = path => this.props.history.push(path)

    deleteCompanyProduct = async () => {
        const { deleteProductId, company } = this.state
        await deleteCompanyProductRequest(company?._id, deleteProductId)
        this.getCompany()
        this.getCompanyStats()
    }

    editCompanyProduct = async (product) => {
        try {
            const { company } = this.state
            this.setState({ formModalIsLoading: true, formModalError: '' })
            await editCompanyProductRequest(company?._id, product.product._id, product)
            this.setState({ formModalIsLoading: false })
            this.getCompany()
            this.getCompanyStats()
            this.toggleCompanyProductModal(false)
        } catch (e) {
            this.setState({
                formModalError: e.message
            })
        }
    }

    createCompanyProduct = async (product) => {
        try {
            const { company } = this.state
            this.setState({ formModalIsLoading: true, formModalError: '' })
            await saveProductIntoCompanyRequest({
                product,
                companyId: company._id
            })
            this.setState({ formModalIsLoading: false })

            this.getCompany()
            this.getCompanyStats()

            this.toggleCompanyProductModal(false)
        } catch (e) {
            this.setState({
                formModalError: e.message
            })
        }
    }

    openDeleteConfirmationModal = (deleteId) => {
		this.setState({
			deleteProductId: deleteId
		});
		this.deleteConfirmationModal.toggle();
    }

    toggleCompanyProductModal (isOpen) {
        this.setState({
            formModalIsOpen: isOpen
        })
    }

    openEditConfirmationModal = (product) => {
		this.setState({
			editProduct: product,
            isCreateModal: false,
		});
        this.toggleCompanyProductModal(true)
    }

    openCreateConfirmationModal = () => {
		this.setState({
            isCreateModal: true,
            editProduct: null
		});
        this.toggleCompanyProductModal(true)
    }


    render() {
        const {
            company,
            stats,
            editProduct,
            isCreateModal,
            formModalError,
            formModalIsOpen,
            formModalIsLoading
        } = this.state

        const {
            _id,
            name,
            role,
            owner,
            alternateEmail,
            address,
            createdAt,
            updatedAt,
        } = company || {}
        
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Stats
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                {!company || !stats
                                    ? <div>Loading...</div>
                                    :   <Row>
                                            <Col lg="6" md="6">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                    <Row>
                                                        <Col xs="5">
                                                        <div className="info-icon text-center icon-warning">
                                                            <i className="tim-icons icon-single-02" />
                                                        </div>
                                                        </Col>
                                                        <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">{company.role === "seller" ? "Total Sales" : "Total Orders"}</p>
                                                            <CardTitle tag="h3">{stats.count || 0}</CardTitle>
                                                        </div>
                                                        </Col>
                                                    </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                    <Row>
                                                        <Col xs="5">
                                                        <div className="info-icon text-center icon-warning">
                                                            <i className="tim-icons icon-single-02" />
                                                        </div>
                                                        </Col>
                                                        <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">{company.role === "seller" ? "Total Revenue" : "Total Spent"}</p>
                                                            <CardTitle tag="h3">$ {formatPrice(stats.total)}</CardTitle>
                                                        </div>
                                                        </Col>
                                                    </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Company Details
                                    </CardTitle>
                                    <Button
                                        color="default"
                                        onClick={() => this.goTo('/companies')}
                                    >
                                        Back
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    {!company 
                                        ? <div>Loading...</div>
                                        : <>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>ID</span>
                                                    {_id}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Name</span>
                                                    {name}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Role</span>
                                                    {formatRole(role)}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Owner Email</span>
                                                    {owner?.email || 'N/A'}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Alternate Email</span>
                                                    {alternateEmail || 'N/A'}
                                                </h4>
                                            </div>
                                            {role === "seller" &&
                                                <Card className='detail-card'>
                                                    <CardHeader>
                                                        <h3>Address</h3>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>State</span>
                                                                {formatStateName(address?.state) || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>City</span>
                                                                {address?.city || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>Street Address</span>
                                                                {address?.streetAddress || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>Notes</span>
                                                                {address?.notes || 'N/A'}
                                                            </h4>
                                                        </div>
                                                        <div className="typography-line">
                                                            <h4>
                                                                <span>ZIP Code</span>
                                                                {address?.zipCode || 'N/A'}
                                                            </h4>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            }
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Created At</span>
                                                    {moment(createdAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}
                                                </h4>
                                            </div>
                                            <div className="typography-line">
                                                <h4>
                                                    <span>Updated At</span>
                                                    {moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}
                                                </h4>
                                            </div>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        {company?.role === 'seller' ?
                            <Col>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <CardTitle tag="h3">
                                            Company Products
                                        </CardTitle>
                                        <Button className="pull-right mb-3" color="primary" onClick={this.openCreateConfirmationModal}>Add Product</Button>
                                        {/* <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
                                    </CardHeader>
                                    <CardBody>
                                        <Table responsive>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Image</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Has Discount</th>
                                                    <th>Original price</th>
                                                    <th>Quantity</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {company.products && company.products.length
                                                ? company.products.map((product) => {
                                                    return (
                                                        <tr key={product._id}>
                                                            <td>{product.product.images && product.product.images.length > 0
                                                                    ? <a href={product.product.images[0]} target="_blank" rel="noreferrer"><img alt='Product' className="product-preview" src={product.product.images[0]} /></a> 
                                                                    : <img alt='Product placeholder' className="product-preview" src={productPlaceholder} />
                                                                }
                                                            </td>
                                                            <td>{product.product.name || "N/A"}</td>
                                                            <td>$ {formatPrice(product.price )|| "N/A"}</td>
                                                            <td>{product.hasDiscount ? 'Yes' : 'No'}</td>
                                                            <td>{product.hasDiscount ? `$ ${formatPrice(product.comparePrice )}` : "N/A"}</td>
                                                            <td>{product.quantity}</td>
                                                            <td>
                                                                <Button
                                                                    className="btn-link btn-icon"
                                                                    color="info"
                                                                    id={`tooltip-edit-${product._id}`}
                                                                    size="sm"
                                                                    onClick={() => this.openEditConfirmationModal(product)}
                                                                >
                                                                    <i className="tim-icons icon-pencil" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target={`tooltip-edit-${product._id}`}
                                                                >
                                                                    Edit
                                                                </UncontrolledTooltip>
                                                                <Button
                                                                    className="btn-link btn-icon"
                                                                    color="danger"
                                                                    id={`tooltip-remove-${product._id}`}
                                                                    size="sm"
                                                                    onClick={() => this.openDeleteConfirmationModal(product.product._id)}
                                                                >
                                                                    <i className="tim-icons icon-simple-remove" />
                                                                </Button>
                                                                <UncontrolledTooltip
                                                                    delay={0}
                                                                    target={`tooltip-remove-${product._id}`}
                                                                >
                                                                    Remove
                                                                </UncontrolledTooltip>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :   <tr>
                                                        <td colSpan="6">There's no product in the database</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </Table> 
                                    </CardBody>
                                </Card>
                            </Col>
                            : null
                        }
                    </Row>
                    <ConfirmationModal
                        ref={el => this.deleteConfirmationModal = el}
                        title="Remove Product?"
                        buttons={[
                            { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                            { title: 'Remove', className: 'btn-danger-override btn btn-danger', onClick: this.deleteCompanyProduct }
                        ]}
                    >
                        Are you sure you want to remove this product from your inventory?
                    </ConfirmationModal>
                    <ProductStoreFormModal 
                       isCreateModal={isCreateModal}
                       editProduct={editProduct}
                       onSave={isCreateModal ? this.createCompanyProduct : this.editCompanyProduct}
                       closeModal={() => this.toggleCompanyProductModal(false)}
                       isOpen={formModalIsOpen}
                       error={formModalError}
                       isLoading={formModalIsLoading} 
                    />
                </div>
            </>
        )
    }
}

export default CompanyDetails