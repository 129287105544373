import React from "react";
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input,
	Alert
} from "reactstrap";
// Components
import ProductUploadArea from "components/ProductUpload/ProductUploadArea";
// Helpers
import { getFormattedDate } from "helpers/date";
import { getCredentials } from "helpers/auth";
import { getCategoryChildren, getLevelOneCategories } from "helpers/category";
// Requests
import { getProductRequest, updateProductRequest, createProductRequest } from "api/product";
// Constants
import { colors } from "constants/colors";
import { sizes } from "constants/sizes";

class ProductForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			product: null,
			error: null,
			images: [],
			deleteImagesFromBucket: [],
			saving: false
		}
	}

	async componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

		if (id) {
			try {
				const product = await getProductRequest(id);
				const specifications = (product.specifications && Object.keys(product.specifications).map(spec => ({ key: spec, value: product.specifications[spec] }))) || [];
				const relevance = product.relevance || 5;

				this.setState({
					product: {
						...product,
						specifications,
						relevance
					},
					images: this.formatImagesArray(product.images),
				})
			} catch(err) {
				this.setState({
					error: 'There was an error fetching product'
				});
			}
		} else {
			// case for adding a product
			this.setState({
				product: {
					name: '',
					status: 'created',
					relevance: 5
				}
			})
		}

	}

	formatImagesArray = (result=[]) => result.map(image => ({ file: '', src: image }));

	goTo = path => this.props.history.push(path);

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			product: {
				...prevState.product,
				[name]: value,
			},
		}));
	}

	onChangeCheckbox = (e) => {
		const { target } = e;
		const { name, checked } = target;

		this.setState(prevState => ({
			product: {
				...prevState.product,
				[name]: checked,
			},
		}));
	}

	save = async () => {
		const { product } = this.state;
		const { _id } = product;

		this.setState({
			error: null,
			saving: true
		});

		const specifications = {};
		product.specifications && product.specifications.forEach(spec => {
			specifications[spec.key] = spec.value;
		});

		try {
			if (_id) {
				const result = await updateProductRequest({...product, specifications});
				this.productUploadArea.saveImages(result, result._id)
			} else {
				const result =  await createProductRequest({...product, specifications});
				this.productUploadArea.saveImages(result, result._id)
			}
			this.setState({
				error: null,
				saving: false
			})
			this.goTo('/products');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving product',
				saving: false
			})
		}
	}

	saveSuccess = () => {
        this.setState({ 
			error: null,
			saving: false
		})
        this.goTo('/products');
    }

	saveFailure = () => {
        this.setState({ 
			saving: false
		})
    }

	onSelectChange = (key) => (tags) => {
		this.onChangeField({ target: { value: tags, name: key } });
	}

	handleSpecChange = (specificationIndex) => (event) => {
		const { name, value } = event.target;
		const { specifications } = this.state.product;
		const updatedSpecifications = [...specifications];
		updatedSpecifications[specificationIndex] = {
			...updatedSpecifications[specificationIndex],
			[name]: value
		}
		this.setState((prevState) => ({
			product: {
				...prevState.product,
				specifications: updatedSpecifications
			}
		}));
	}

	addSpecification = () => {
		const { specifications=[] } = this.state.product;
		this.setState((prevState) => ({
			product: {
				...prevState.product,
				specifications: [
					...specifications,
					{
						key: '',
						value: ''
					}
				]
			}
		}))
	}

	deleteSpecification = (specificationIndex) => () => {
		const { specifications=[] } = this.state.product;
		const updatedSpecifications = [...specifications];
		updatedSpecifications.splice(specificationIndex, 1);
		this.setState((prevState) => ({
			product: {
				...prevState.product,
				specifications: updatedSpecifications
			}
		}));
	}

	onChangeCategory = (key) => (event) => {
		const { value } = event.target;
		this.setState((prevState) => {
			return ({
				product: {
					...prevState.product,
					[key]: value
				}
			})
		});
	}

	render() {

		const {
			product = {},
			error=null,
			images=[],
			deleteImagesFromBucket,
			saving
		} = this.state;

		const {
			_id,
			name,
			description,
			specifications=[],
			status,
			sku,
			size,
			color,
			brand,
			categoryLevelOne,
			categoryLevelTwo,
			/*unityType,
			packageType,
			packageDiameter,*/
			grossWeight,
			packageHeight,
			packageLength,
			packageWidth,
			relevance,
			createdAt,
			updatedAt
		} = product || {};

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										{_id ? "Edit product" : "Create product"}
                  </CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/products')}
									>
										Back
                  </Button>
								</CardHeader>
								<CardBody>
									{!product
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>SKU</Label>
												<Input
													type="text"
													name="sku"
													value={sku || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Name <span className="danger-text">*</span></Label>
												<Input
													type="text"
													name="name"
													value={name || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Description <span className="danger-text">*</span></Label>
												<Input
													type="textarea"
													style={{
														minHeight: '100px'
													}}
													name="description"
													value={description || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Status <span className="danger-text">*</span></Label>
												<Input
													type="select"
													name="status"
													value={status}
													onChange={this.onChangeField}
												>
													<option value={''}>-- SELECT --</option>
													<option value='created'>Created</option>
													<option value='available'>Available</option>
													<option value='withdrawn'>Withdrawn</option>
												</Input>
											</FormGroup>
											
											<FormGroup>
												<Label>
													Images
												</Label>
												<div>
													<ProductUploadArea
														parent={this}
														images={images}
														totalOfCards={6}
														deleteImagesFromBucket={deleteImagesFromBucket}
														ref={(element) => this.productUploadArea = element}
														onSaveFailure={this.saveFailure}
														onSaveSuccess={this.saveSuccess}
													/>
												</div>
											</FormGroup>
											<FormGroup>
												<Label>Category Level 1 <span className="danger-text">*</span></Label>
												<Input
													type="select"
													name="category"
													className="category-select"
													value={categoryLevelOne}
													onChange={this.onChangeCategory('categoryLevelOne')}
												>
													<option value={''}>-- SELECT --</option>
													{
														getLevelOneCategories().map(cat => {
															return <option key={cat.id} value={cat.id}>{cat.name}</option>
														})
													}
												</Input>
											</FormGroup>
											{categoryLevelOne ? <FormGroup>
												<Label>Category Level 2 <span className="danger-text">*</span></Label>
												<Input
													type="select"
													name="category"
													className="category-select"
													value={categoryLevelTwo}
													onChange={this.onChangeCategory('categoryLevelTwo')}
												>
													<option value={''}>-- SELECT --</option>
													{
														getCategoryChildren(categoryLevelOne).map(cat => {
															return <option key={cat.id} value={cat.id}>{cat.name}</option>
														})
													}
												</Input>
											</FormGroup> : null}
											<FormGroup>
												<Label>Relevance</Label>
												<Alert color="info">
													Field used to manipulate the order in which products will appear on the app.
													<br></br>
													Acepts values between 1 and 10. If left blank it will be set to 5 automatically. 
													<br></br>
													The products will appear on the app in descending order (10, 9, 8, ...)
												</Alert>
												<Input
													type="number"
													name="relevance"
													value={relevance || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label className="d-flex align-items-center">
													Product Specifications (Technical details)
													<i role="button" onClick={this.addSpecification} className="tim-icons icon-simple-add ml-2 cursor-pointer" />
												</Label>
												{
													specifications.map((specification, index) => {
														return <Row className="my-3" key={index}>
															<Col sm={5}>
																<Input
																	type="text"
																	name="key"
																	placeholder="Specification Name"
																	value={specification.key}
																	onChange={this.handleSpecChange(index)}
																/>
															</Col>
															<Col sm={5}>
																<Input
																	type="text"
																	name="value"
																	placeholder="Specification Value"
																	value={specification.value}
																	onChange={this.handleSpecChange(index)}
																/>
															</Col>
															<Col className="d-flex align-items-center" sm={2}>
																<i onClick={this.deleteSpecification(index)} className="tim-icons icon-trash-simple cursor-pointer" />
															</Col>
														</Row>
													})
												}
											</FormGroup>
											<FormGroup>
												<Label>Size</Label>
												<Input
													type="select"
													name="size"
													value={size || ''}
													onChange={this.onChangeField}
												>
													<option value="">-- SELECT --</option>
													{
														sizes.map((size, index) => {
															return <option key={index} value={size.value}>{size.size}</option>
														})
													}
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Color</Label>
												<Input
													type="select"
													name="color"
													value={color || ''}
													onChange={this.onChangeField}
												>
													<option value="">-- SELECT --</option>
													{
														colors.map((color, index) => {
															return <option key={index} value={color.color}>{color.color}</option>
														})
													}
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Brand <span className="danger-text">*</span></Label>
												<Input
													type="text"
													name="brand"
													value={brand || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<Card className="detail-card">
												<CardHeader>
												<h3>Dimensions</h3>
												<Alert color="info">
													Dimensions: In in. (Eg: 23)
													<br></br>
													Weight: In lb. (Eg: 7.45)
												</Alert>
												</CardHeader>
												<CardBody>
													<FormGroup>
														<Label>Height</Label>
														<Input
															type="number"
															name="packageHeight"
															value={packageHeight}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Width</Label>
														<Input
															type="number"
															name="packageWidth"
															value={packageWidth}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Length</Label>
														<Input
															type="number"
															name="packageLength"
															value={packageLength}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Weight</Label>
														<Input
															type="number"
															name="grossWeight"
															value={grossWeight}
															onChange={this.onChangeField}
														/>
													</FormGroup>
												</CardBody>
											</Card>
											{/*<FormGroup>
												<Label>Unity Type</Label>
												<Input
													type="text"
													name="unityType"
													value={unityType || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Package Type</Label>
												<Input
													type="text"
													name="packageType"
													value={packageType || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Package Diameter</Label>
												<Input
													type="text"
													name="packageDiameter"
													value={packageDiameter || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>*/}
											{_id ? <><FormGroup>
												<Label>Created At</Label>
												<Input
													type="text"
													name="createdAt"
													value={getFormattedDate(createdAt || '')}
													readOnly
												/>
											</FormGroup>
											<FormGroup>
												<Label>Updated At</Label>
												<Input
													type="text"
													name="updatedAt"
													value={getFormattedDate(updatedAt || '')}
													readOnly
												/>
											</FormGroup></> : null}
										</>
									}
									{error ? <div className="alert alert-danger mt-3">
											<span>{error}</span>
										</div> : null
									}
								</CardBody>
								{product ? <Col>
									<Button 
										color="primary" 
										block={false} 
										className="mb-4" 
										onClick={this.save}
										disabled={saving}
									>
										{saving
											? "Saving..."
											:_id
												? "Save Changes"
												: "Create Product"
										}
									</Button>
								</Col> : null}
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default ProductForm;
