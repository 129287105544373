export const formatTransactionType = (type) => {
	let formattedType = "";

    switch(type) {
        case 'sale':
            formattedType = "Sale";
            break;
        case 'purchase':
            formattedType = "Purchase";
            break;
        case 'commission':
            formattedType = "Commission";
            break;
        case 'withdrawal':
            formattedType = "Withdrawal";
            break;
        case 'discount':
            formattedType = "Discount";
            break;
        case 'serviceFee':
            formattedType = "Service Fee";
            break;
        case 'deliveryFee':
            formattedType = "Delivery Fee";
            break;
        case 'taxes':
            formattedType = "Taxes";
            break;
        case 'fees':
            formattedType = "Fees";
            break;
        case 'order':
            formattedType = "Order";
            break;
        case 'driverFee':
            formattedType = "Driver Fee";
            break;
        case 'otherFees':
            formattedType = "Other Fees";
            break;
        default:
            formattedType = type;
    }

	return formattedType;
}