const createImage = url =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

const urlToFile = (url, filename) => {
	const type = (url.match(/^data:([^;]+);/) || '')[1];
	return (
		fetch(url)
			.then(res => res.arrayBuffer())
			.then(buf => new File([buf], filename, { type }))
	);
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	canvas.width = pixelCrop.width
	canvas.height = pixelCrop.height
	const ctx = canvas.getContext('2d')

	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	)

	// As Base64 string 
	const base64Image = canvas.toDataURL('image/jpeg');
	// Create File from url
	const fileImage = await urlToFile(base64Image, new Date());
	return {
		file: fileImage,
		src: base64Image
	}

	// As a blob
	//   return new Promise((resolve, reject) => {
	//     canvas.toBlob(file => {
	//       resolve(URL.createObjectURL(file))
	//     }, 'image/jpeg')
	//   })
}
