export const formatPrice = (price=0) => {
	if (typeof price === 'string') {
		price = Number(price);
	}
	let formattedPrice = (price || 0).toFixed(2);
	const centIndex = formattedPrice.lastIndexOf(".");

	if (centIndex !== -1) {
		formattedPrice = [...formattedPrice];
		formattedPrice[centIndex] = '.';
		return formattedPrice.join('');

	}
	return formattedPrice;
}
