import React from "react";
import { 
    Button, 
    Card, 
    CardBody, 
    CardFooter, 
    CardHeader, 
    CardTitle, 
    Col, 
    Row, 
    Table, 
    UncontrolledTooltip 
} from "reactstrap";
import { Link } from "react-router-dom";
import PaginationComponent from "components/Custom/Pagination";
import { calcStartEndPage } from "helpers/pagination";
import { formatRole } from "helpers/strings";
import { getCorporationsRequest, getCorporationStatsRequest, deleteCorporationRequest } from '../../api/corporation'
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";

class Corporations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            corporations: null,
            page: 0,
            total: 0,
            limit: 25,
            pages: 0,
            current_page: 0,
            start_page: 0,
            end_page: 9,
            first_page: 0,
            last_page: 0,
            search: '',
            role: '',
            stats: null,
            deleteId: ''
        }
        this.timeout = null;
    }

    componentDidMount() {
        this.getCorporations()
        this.getStats()
    }

    getCorporations = async () => {
        try {
            const { page, limit, search, role } = this.state
    
            const { corporations, total } = await getCorporationsRequest(page, limit, search, role)
    
            const pages = Math.ceil(total / limit);
            const last_page = pages - 1;
            const end_page = pages > 10 ? 9 : pages - 1;
    
            this.setState({
                corporations,
                total,
                pages,
                last_page,
                end_page
            })        
        } catch (error) {
            console.log('error', error)
        }
    }

    getStats = async () => {
        try {
            const stats = await getCorporationStatsRequest();
            console.log(stats)

            this.setState({
                stats
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    goTo = path => this.props.history.push(path)

    goToPage = async (page) => {
        const { limit, pages, search, role } = this.state;
        const { corporations } = await getCorporationsRequest(page, limit, search, role);
        const { start_page, end_page } = calcStartEndPage(page, pages);

        this.setState({
            corporations,
            current_page: page,
            start_page,
            end_page
        })
    }

    openConfirmation = (deleteId) => () => {
		this.setState({
			deleteId
		});
		this.deleteConfirmationModal.toggle();
    }
    
    deleteUser = async () => {
		const { deleteId } = this.state;
        await deleteCorporationRequest(deleteId);
        this.getCorporations()
	}

    render () {
        const {
            corporations,
            pages,
            current_page,
            end_page,
            start_page,
            stats
        } = this.state

        const { clientCount = 0, sellerCount = 0 } = stats || {}

        return (
            <div className="content">
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                        <CardHeader>
                            <Row>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-bank" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total client corporations</p>
                                                <CardTitle tag="h3">{clientCount}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-bank" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Total sellers corporations</p>
                                                <CardTitle tag="h3">{sellerCount}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </CardHeader>
                        </Card>
                    </Col>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle className="d-inline-block" tag="h4">
                                    Corporations
                                </CardTitle>
                                {/* <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo('/user/create')}>Add User</Button>
                                <Input placeholder="Search Users..." value={search} type="text" onChange={this.onChangeSearch}></Input> */}
                            </CardHeader>
                            <CardBody>
                                <Table responsive>
                                    <thead className="text-primary">
                                        <tr>
                                            <th>Name</th>
                                            <th>Owner</th>
                                            <th>Role</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {corporations && corporations.length > 0 
                                        ? corporations.map(({
                                            _id, 
                                            name,
                                            role,
                                            owner
                                        }) => (
                                            <tr key={_id}>
                                                <td>{name || 'N/A'}</td>
                                                <td>{owner 
                                                    ? <Link to={`/user?id=${owner._id}`}>{owner.email}</Link>
                                                    : "N/A"
                                                }</td>
                                                <td>{formatRole(role) || 'N/A'}</td>
                                                <td className="text-right">
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="success"
                                                        id={`tooltip-view-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/corporation?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-zoom-split" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-view-${_id}`}
                                                    >
                                                        View
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="info"
                                                        id={`tooltip-edit-${_id}`}
                                                        size="sm"
                                                        onClick={() => this.goTo(`/corporation/edit?id=${_id}`)}
                                                    >
                                                        <i className="tim-icons icon-pencil" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-edit-${_id}`}
                                                    >
                                                        Edit
                                                    </UncontrolledTooltip>
                                                    <Button
                                                        className="btn-link btn-icon"
                                                        color="danger"
                                                        id={`tooltip-delete-${_id}`}
                                                        size="sm"
                                                        onClick={this.openConfirmation(_id)}
                                                    >
                                                        <i className="tim-icons icon-simple-remove" />
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        target={`tooltip-delete-${_id}`}
                                                    >
                                                        Delete
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        ))
                                        : <tr>
                                            <td colSpan="10">There's no corporation in the database.</td>
                                        </tr>
                                        }
                                        
                                    </tbody>
                                </Table>
                                {corporations?.length > 0 && 
                                    <PaginationComponent
                                        pages={pages}
                                        current_page={current_page}
                                        start_page={start_page}
                                        end_page={end_page}
                                        goToPage={this.goToPage}
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ConfirmationModal
                    ref={el => this.deleteConfirmationModal = el}
                    title="Delete Corporation?"
                    buttons={[
                        { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                        { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteUser }
                    ]}
                >
                    Are you sure you want to delete this corporation?
                </ConfirmationModal>
            </div>
        )
    }
}

export default Corporations