import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';
import { getRunRequest } from '../../api/run';
import ImageCard from '../../components/ImageCard/ImageCard';
import { formatPrice } from "helpers/pricing";
import { formatRunStatus } from "helpers/status";
import { getCategoryNameById } from "helpers/category";
import { Link } from "react-router-dom";

class RunDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          run: null
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');

        if (id) {
            const run = await getRunRequest(id);
            this.setState({ run })
        } else {
            this.goTo('/runs')
        }
    }
  
    goTo = path => this.props.history.push(path);

    render() {

        const {
            run={}
        } = this.state;

        const {
            _id,
            createdAt,
            updatedAt,
            startedAt,
            endedAt,
            status,
            driver,
            order,
            transfer
        } = run || {};

        return (
            <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader className="mb-5">
                      <CardTitle tag="h3">
                        Run Details
                      </CardTitle>
                      <Button 
                        className="float-right"
                        color="default"
                        onClick={() => this.goTo('/runs')}
                      >
                        Back
                      </Button>
                    </CardHeader>
                    <CardBody>
                      {!run 
                      ? <div>Loading...</div>
                      : <>
                          <div className="typography-line">
                            <h4>
                              <span>ID</span>
                              {_id}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Status</span>
                              {status ? formatRunStatus(status) : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Driver</span>
                              {driver ? <Link to={`/user?id=${driver.user}`}>{driver.firstName} {driver.lastName}</Link> : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Order</span>
                              {order ? <Link to={`/order?id=${order._id}`}>{order._id}</Link> : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Transfer</span>
                              {transfer ? <Link to={`/transfer?id=${transfer._id}`}>{transfer._id}</Link> : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Created At</span>
                              {createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Started At</span>
                              {createdAt ? moment(startedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Ended At</span>
                              {createdAt ? moment(endedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Updated At</span>
                              {updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>

                          <Card className="detail-card">
                            <CardHeader>
                              <h3>Pickup Address</h3>
                            </CardHeader>
                            <CardBody>
                              <div className="typography-line">
                                <h4>
                                  <span>Street Address</span>
                                  {run?.pickupAddress?.streetAddress || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>City</span>
                                  {run?.pickupAddress?.city || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>State</span>
                                  {run?.pickupAddress?.state || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>Zip Code</span>
                                  {run?.pickupAddress?.zipCode || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>Notes</span>
                                  {run?.pickupAddress?.notes || 'N/A'}
                                </h4>
                              </div>
                            </CardBody>
                          </Card>

                          <Card className="detail-card">
                            <CardHeader>
                              <h3>Delivery Address</h3>
                            </CardHeader>
                            <CardBody>
                              <div className="typography-line">
                                <h4>
                                  <span>Street Address</span>
                                  {run?.deliveryAddress?.streetAddress || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>City</span>
                                  {run?.deliveryAddress?.city || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>State</span>
                                  {run?.deliveryAddress?.state || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>Zip Code</span>
                                  {run?.deliveryAddress?.zipCode || 'N/A'}
                                </h4>
                              </div>
                              <div className="typography-line">
                                <h4>
                                  <span>Notes</span>
                                  {run?.deliveryAddress?.notes || 'N/A'}
                                </h4>
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      }
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
    }
}

export default RunDetails;
