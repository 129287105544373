import { categories } from "constants/categories";

const categoryMap = {};
categories.forEach(cat => {
    categoryMap[cat.id] = cat;
});

export const buildCategoriesObj = () => {
    const obj = {};
    categories.forEach(cat => {
        obj[cat.id] = cat;
    });

    return obj;
}

export const getCategoryTreeById = (categoryId) => {
    if(!categoryId){
		return '';
	}
	let categoryTree = [];
    let parentId = null;
    let currentNode = categoryMap[categoryId];
    if(!currentNode){
    	return '';
    }
    categoryTree.push(currentNode.id);
    parentId = currentNode.child_of;

    while(parentId != null){
    	currentNode = categoryMap[parentId];
    	categoryTree.unshift(currentNode.id);
    	parentId = currentNode.child_of;
    }
    return categoryTree;
}

export const getLevelOneCategories = () => {
    return categories.filter(category => category.child_of === null);
}

export const getCategoryChildren = (categoryId) => {
    if(!categoryId) {
        return [];
    }
    categoryId = categoryId.toString();
    return categories.filter(category => category.child_of === categoryId);
}

export const buildCategorySelectOptions = () => {
    let formattedList = [];
    categories.forEach((category) => {
        let categoryTree = [];
        let parentId = null;
        let currentNode = categoryMap[category.id];
        if(!currentNode){
            return '';
        }
        categoryTree.push(currentNode.name);
        parentId = currentNode.child_of;

        while(parentId != null){
            currentNode = categoryMap[parentId];
            categoryTree.unshift(currentNode.name);
            parentId = currentNode.child_of;
        }
        const label = categoryTree.join('-');
        let categoryObj = {
            label: label,
            value: category.id,
            data: category
        }
        formattedList.push(categoryObj);
    })

    return formattedList;
}

export const buildCategorySelectLabelById = (categoryId) => {
    
	let categoryLabelArray = [];
    let parentId = null;
    let currentNode = categoryMap[categoryId];
    if(!currentNode){
    	return '';
    }
    categoryLabelArray.push(currentNode.name);
    parentId = currentNode.child_of;

    while(parentId != null){
    	currentNode = categoryMap[parentId];
    	categoryLabelArray.unshift(currentNode.name);
    	parentId = currentNode.child_of;
    }
    const label = categoryLabelArray.join('-');
    return label;
}

export const getCategoryNameById = (categoryId) => {
    if(!categoryId){
		return '';
	}

    let currentNode = categoryMap[categoryId];
    if(!currentNode){
    	return '';
    }

    const name = currentNode.name;

    return name;
}