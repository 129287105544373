import { apiRequest } from "api"

export const updateClientRequest = (client) => {
  return apiRequest(
    `/admin/clients/${client._id}`,
    {
      method: 'PUT',
      body: JSON.stringify(client)
    },
    true
  )
}

export const getClientsRequest = (page, limit, term) => {
  return apiRequest(
    `/admin/clients?page=${page}&limit=${limit}&search=${term}`,
    {
      method: 'GET'
    },
    true
  )
}