import React from 'react';
//import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal/Modal';
import { Container, Row, Col, FormGroup, Label, Input, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../helpers/image';
import addImage from '../../assets/img/add-image.png';

class CropImageModal extends React.Component {
	state = {
		modal: false,
		image: addImage,
		crop: { x: 0, y: 0 },
		zoom: 1,
		aspect: 3 / 3,
		croppedAreaPixels: null,
		croppedImage: null,
	}

	toggle = () =>
		this.setState(prevState => ({
			modal: !prevState.modal
		}));

	setImage = (image) => this.setState({ image });

	onCropChange = crop => {
		this.setState({ crop })
	}

	onZoomChange = zoom => {
		this.setState({ zoom })
	}

	onZoomRangeChange = event => {
		this.setState({ zoom: event.target.value });
	}

	onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({ croppedAreaPixels })
	}

	onCropSave = async () => {
		const { image, croppedAreaPixels } = this.state;
		const { onSave = (file, src) => { } } = this.props;

		const croppedImage = await getCroppedImg(
			image,
			croppedAreaPixels
		);

		onSave(croppedImage.file, croppedImage.src);
		this.toggle();
	}

	render() {
		const {
			options,
		} = this.props;

		const {
			title = 'Crop Image',
			tips = 'No tips available',
		} = options;

		return (
			<Modal isOpen={this.state.modal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>{title}</ModalHeader>
				<ModalBody>
					<Container>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Label>{tips}</Label>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<div style={{ width: '100%', height: '300px', position: 'relative', top: '0', left: '0', right: '0' }}>
									<Cropper
										{...options}
										image={this.state.image}
										crop={this.state.crop}
										zoom={this.state.zoom}
										onCropChange={this.onCropChange}
										onCropComplete={this.onCropComplete}
										onZoomChange={this.onZoomChange}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12}>
								<FormGroup>
									<Input value={this.state.zoom} type="range" min="1" max="3" step="0.1" onChange={this.onZoomRangeChange} />
								</FormGroup>
							</Col>
						</Row>
					</Container>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={this.onCropSave}>Save</Button>
					<Button color="secondary" onClick={this.toggle}>Cancel</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

export default CropImageModal;