/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink
  } from "reactstrap";

class PaginationComponent extends React.Component {
    render() {

        const {
            pages,
            current_page,
            start_page,
            end_page
        } = this.props;
        
        let paginationItems = [];
        for (let i = start_page; i <= end_page; i++) {
            paginationItems.push(i === current_page ? 
                <PaginationItem key={i} onClick={() => this.props.goToPage(i)} active>
                    <PaginationLink href="#">
                        {i + 1}
                    </PaginationLink>
                </PaginationItem> :
                <PaginationItem key={i} onClick={() => this.props.goToPage(i)}>
                    <PaginationLink href="#">
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>);
        }

        return (
            <Pagination listClassName="justify-content-center">
                {current_page === 0 ?
                    <PaginationItem disabled>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(0)}>
                            First
                        </PaginationLink>
                    </PaginationItem> : 
                    <PaginationItem >
                        <PaginationLink href="#" onClick={() => this.props.goToPage(0)}>
                            First
                        </PaginationLink>
                    </PaginationItem>
                }
                {current_page === 0 ?
                    <PaginationItem disabled>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(current_page - 1)}>
                            Previous
                        </PaginationLink>
                    </PaginationItem> : 
                    <PaginationItem >
                        <PaginationLink href="#" onClick={() => this.props.goToPage(current_page - 1)}>
                            Previous
                        </PaginationLink>
                    </PaginationItem>
                }
                <div className="page-items">
                    {paginationItems}
                </div>
                {current_page === pages - 1 ?
                    <PaginationItem disabled>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(current_page + 1)}>
                            Next
                        </PaginationLink>
                    </PaginationItem> :
                    <PaginationItem>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(current_page + 1)}>
                            Next
                        </PaginationLink>
                    </PaginationItem>
                }
                {current_page === pages - 1 ?
                    <PaginationItem disabled>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(pages - 1)}>
                            Last
                        </PaginationLink>
                    </PaginationItem> :
                    <PaginationItem>
                        <PaginationLink href="#" onClick={() => this.props.goToPage(pages - 1)}>
                            Last
                        </PaginationLink>
                    </PaginationItem>
                }
            </Pagination>
        )

    }
}


export default PaginationComponent;
