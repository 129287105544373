import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	FormGroup,
	Label,
	Input
} from "reactstrap";
import moment from 'moment';
import { createUserRequest } from "api/user";
import Datetime from "react-datetime";
import { isValidDateOfBirth } from "helpers/date";
import states from '../../constants/states.json'

class UserCreate extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			user: null,
			error: null
		}
	}

	async componentDidMount() {
		this.setState({
			user: {
				name: '',
			}
		})
	}

	goTo = path => this.props.history.push(path);

	onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
		this.setState(prevState => ({
			user: {
				...prevState.user,
				[name]: value,
			},
		}));
	}

	onChangeCheckbox = (e) => {
		const { target } = e;
		const { name, checked } = target;

		this.setState(prevState => ({
			user: {
				...prevState.user,
				[name]: checked,
			},
		}));
	}

	save = async () => {
		const { user } = this.state;
		
		try {
			await createUserRequest(user);
			this.goTo('/users');
		} catch (err) {
			console.log(err, "error");
			this.setState({
				error: err.message || 'There was an error saving user'
			})
		}
	}

	onChangeAddress = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			user: {
				...prevState.user,
				address: {
					...(prevState.user.address || {}),
					[name]: value
				}
			}
		}));
	}

	render() {

		const {
			user = {},
			error = null
		} = this.state;

		const {
			firstName,
			lastName,
			email,
			password,
			phone,
			companyName,
			role,
			emailVerified,
			dateOfBirth,
			isAdmin,
			gender,
			streetAddress,
			city,
			state,
			notes,
			zipCode
		} = user || {};

		const isSeller = role === 'seller'

		return (
			<>
				<div className="content">
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="mb-5">
									<CardTitle tag="h3">
										Create User
                  					</CardTitle>
									<Button
										className="float-right"
										color="default"
										onClick={() => this.goTo('/users')}
									>
										Back
 					                </Button>
								</CardHeader>
								<CardBody>
									{!user
										? <div>Loading...</div>
										: <>
											<FormGroup>
												<Label>First Name</Label>
												<Input
													type="text"
													name="firstName"
													value={firstName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Last Name</Label>
												<Input
													type="text"
													name="lastName"
													value={lastName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Role</Label>
												<Input
													type="select"
													name="role"
													value={role || ''}
													onChange={this.onChangeField}
												>
													<option value=''>-- SELECT --</option>
													<option value="client">Client</option>
													<option value="seller">Seller</option>
													<option value="driver">Driver</option>
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Company Name</Label>
												<Input
													type="text"
													name="companyName"
													value={companyName || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											{isSeller 
												&& <>
													<FormGroup>
														<Label>Company Street Address</Label>
														<Input
															type="text"
															name="street"
															value={streetAddress || ''}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Company Street Notes</Label>
														<Input
															type="text"
															name="complement"
															value={notes || ''}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Company City</Label>
														<Input
															type="text"
															name="city"
															value={city || ''}
															onChange={this.onChangeField}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Company State</Label>
														<Input
															type="select"
															name="state"
															value={state || ''}
															onChange={this.onChangeField}
														>
															<option value=''>-- SELECT --</option>
															{states.map(state => (
																<option value={state.value}>{state.name}</option>
															))}
														</Input>
													</FormGroup>
													<FormGroup>
														<Label>Company Zip Code</Label>
														<Input
															type="text"
															name="zipCode"
															value={zipCode || ''}
															onChange={this.onChangeField}
														/>
													</FormGroup>
												</> 
											}
											<FormGroup>
												<Label>Email</Label>
												<Input
													type="text"
													name="email"
													value={email || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Password</Label>
												<Input
													type="text"
													name="password"
													value={password || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Gender</Label>
												<Input
													type="select"
													name="gender"
													value={gender || ''}
													onChange={this.onChangeField}
												>
													<option value=''>-- SELECT --</option>
													<option value={"male"}>Male</option>
													<option value={"female"}>Female</option>
													<option value={"other"}>Other</option>
												</Input>
											</FormGroup>
											<FormGroup>
												<Label>Birthday</Label>
												<Datetime
													inputProps={{
														className: "form-control",
														placeholder: "Birthday",
														name: "dateOfBirth"
													}}
													timeFormat={false}
													value={typeof dateOfBirth === 'string' ? moment(dateOfBirth) : dateOfBirth}
													onChange={(date) => this.onChangeField({target: { value: date, name: "dateOfBirth" }})}
													isValidDate={isValidDateOfBirth}
													closeOnSelect={true}
												/>
											</FormGroup>
											<FormGroup>
												<Label>Telephone</Label>
												<Input
													type="text"
													name="phone"
													value={phone || ''}
													onChange={this.onChangeField}
												/>
											</FormGroup>
											<FormGroup>
												<Row>
													<Col sm={4}>
														<div className="form-check">
															<label className="form-check-label">
																	<input className="form-check-input" onChange={this.onChangeCheckbox} name="emailVerified" type="checkbox" checked={!!emailVerified} />
																	Email Verified
																	<span className="form-check-sign">
																			<span className="check"></span>
																	</span>
															</label>
														</div>
													</Col>
													<Col sm={4}>
														<div className="form-check">
															<label className="form-check-label">
																	<input className="form-check-input" onChange={this.onChangeCheckbox} name="isAdmin" type="checkbox" checked={!!isAdmin} />
																	Is Admin
																	<span className="form-check-sign">
																			<span className="check"></span>
																	</span>
															</label>
														</div>
													</Col>
												</Row>
											</FormGroup>
										</>
									}
									{error ? <div className="alert alert-danger mt-3">
											<span>{error}</span>
										</div> : null
									}
								</CardBody>
								{user ? <Col>
									<Button color="primary" block={false} className="mb-4" onClick={this.save}>
										Create User
									</Button>
								</Col> : null}
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

export default UserCreate;

