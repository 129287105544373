import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col
} from "reactstrap";

class ComingSoon extends React.Component {

    render() {
        
        return (
        <>
            <div className="content">
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardBody className="my-5 text-center">
                            Coming Soon
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </div>
        </>
        );
    }
}

export default ComingSoon;
