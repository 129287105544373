import React from 'react'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import moment from 'moment'
import { Link } from "react-router-dom";
// Requests
import { getCorporationRequest } from 'api/corporation'
import { getCompaniesRequest } from 'api/company'
import { getCorporationTransactionsStatsRequest } from 'api/transaction';
// Helpers
import { formatRole } from 'helpers/strings'
import { formatPrice } from "helpers/pricing";

class CorporationDetails extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            corporation: null,
            stores: null,
            stats: null,
            error: null
        }
    }

    componentDidMount() {
        this.getCorporation()
        this.getCorporationStores()
        this.getCorporationStats()
    }

    async getCorporation() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        if (!id) {
            this.goTo('/corporation')
            return
        }

        try {
            const corporation = await getCorporationRequest(id);
            this.setState({
                corporation
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching corporation'
            });
        }
    }

    async getCorporationStores() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        try {
            const storesResponse = await getCompaniesRequest(0, 0, '', '', id);
            this.setState({
                stores: storesResponse.companies
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching stores'
            });
        }
    }

    async getCorporationStats() {
        const params = new URLSearchParams(this.props.location.search);
		const id = params.get('id');

        try {
            const stats = await getCorporationTransactionsStatsRequest(id);
            this.setState({
                stats
            })
        } catch(err) {
            this.setState({
                error: err.message || 'There was an error fetching stores'
            });
        }
    }

    goTo = path => this.props.history.push(path)

    render() {
        const {
            corporation,
            stores,
            stats,
            error
        } = this.state

        const {
            _id,
            name,
            role,
            owner,
            ownerFirstName,
            ownerLastName,
            ownerPhoneNumber,
            createdAt,
            updatedAt
        } = corporation || {}
        
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader className="d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Stats
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                {!corporation || !stats
                                    ? <div>Loading...</div>
                                    :   <Row>
                                            <Col lg="6" md="6">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                    <Row>
                                                        <Col xs="5">
                                                        <div className="info-icon text-center icon-warning">
                                                            <i className="tim-icons icon-single-02" />
                                                        </div>
                                                        </Col>
                                                        <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">{corporation.role === "seller" ? "Total Sales" : "Total Orders"}</p>
                                                            <CardTitle tag="h3">{stats.count || 0}</CardTitle>
                                                        </div>
                                                        </Col>
                                                    </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col lg="6" md="6">
                                                <Card className="card-stats">
                                                    <CardBody>
                                                    <Row>
                                                        <Col xs="5">
                                                        <div className="info-icon text-center icon-warning">
                                                            <i className="tim-icons icon-single-02" />
                                                        </div>
                                                        </Col>
                                                        <Col xs="7">
                                                        <div className="numbers">
                                                            <p className="card-category">{corporation.role === "seller" ? "Total Revenue" : "Total Spent"}</p>
                                                            <CardTitle tag="h3">$ {formatPrice(stats.total)}</CardTitle>
                                                        </div>
                                                        </Col>
                                                    </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Corporation Details
                                    </CardTitle>
                                    <Button
                                        color="default"
                                        onClick={() => this.goTo('/corporations')}
                                    >
                                        Back
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                {!corporation 
                                    ? <div>Loading...</div>
                                    : <>
                                        <div className="typography-line">
                                            <h4>
                                                <span>ID</span>
                                                {_id}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Name</span>
                                                {name}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Role</span>
                                                {formatRole(role)}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Owner Name</span>
                                                {ownerFirstName} {ownerLastName}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Owner Phone Number</span>
                                                {ownerPhoneNumber}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Owner Email</span>
                                                {owner?.email || 'N/A'}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Created At</span>
                                                {moment(createdAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}
                                            </h4>
                                        </div>
                                        <div className="typography-line">
                                            <h4>
                                                <span>Updated At</span>
                                                {moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") || "N/A"}
                                            </h4>
                                        </div>
                                    </>
                                }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardHeader className="mb-5 d-flex justify-content-between">
                                    <CardTitle tag="h3">
                                        Stores
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                {!corporation || !stores
                                    ? <div>Loading...</div>
                                    :   <>
                                            {stores.map((store) => {
                                                return (
                                                    <div className="typography-line">
                                                        <h4>
                                                            <span>Name</span>
                                                            <Link to={`/company?id=${store._id}`}>{store.name}</Link>
                                                        </h4>
                                                    </div>
                                                )
                                            })}    
                                        </>
                                }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default CorporationDetails