import { apiRequest} from './index'

export const getOrdersRequest = (page, limit, search, status, sellerCompany, clientCompany) => {
  return apiRequest(
    `/admin/orders?page=${page}&limit=${limit}&search=${search}&status=${status}&sellerCompany=${sellerCompany}&clientCompany=${clientCompany}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getOrderRequest = (id) => {
  return apiRequest(
    `/admin/orders/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteOrderRequest = (id) => {
  return apiRequest(
    `/admin/orders/${id}`,
    {
      method: 'DELETE'
    },
    true
  )
}

export const getOrdersStatsRequest = () => {
  return apiRequest(
    `/admin/order-stats`,
    {
      method: 'GET'
    },
    true
  )
}