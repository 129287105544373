import { apiRequest } from "api"

export const updateSellerRequest = (seller) => {
    return apiRequest(
      `/admin/sellers/${seller._id}`,
      {
              method: 'PUT',
              body: JSON.stringify(seller)
      },
      true
    )
  }