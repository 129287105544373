import React, { Component } from 'react';
import './ImageCardStyle.css';
import addImage from '../../assets/img/add-image.png';
import CropImageModal from '../CropImageModal/CropImageModal';

import { Button, Label, Input } from 'reactstrap';

// cropOptions sample

/*
    cropOptions = {
        title: '',      // Modal Title
        tips: '',       // Tips for the use. i.e. 'use 300x250 images'
        width: 300,     // width used for imageCard then to set aspect. default = 120;
        height: 300,     // height used for imageCard then to set aspect. default = 120;
        cropShape: 'round',     // use 'rect' | 'round'. default = 'rect';
        // check more crop options at https://github.com/ricardo-ch/react-easy-crop
    }
*/

class ImageCard extends Component {

	state = {
		src: '',
		width_gte_height: true // It is `true` because addImage.width >= addImage.height
	}

	checkDimension = (readerEvent, file, imageEvent) => {
		const { onChange = (file, src) => { } } = this.props;
		// const { width, height } = imageEvent.target;
		// this.setState({ src: readerEvent.target.result, width_gte_height: width >= height });
		onChange(file, readerEvent.target.result);
	}

	checkOrientation = (event) => {
		const { width, height } = event.target;
		this.setState({ width_gte_height: width >= height });
	}

	onReaderLoad = (readerEvent, file) => {
		const { cropOptions = {} } = this.props;
		console.log(cropOptions, file)
		if (Object.keys(cropOptions).length > 0) {
			// if cropOptions set, use cropModal
			this.cropImageModal.setImage(readerEvent.target.result);
			this.cropImageModal.toggle();
		} else {
			// otherwise save without crop.
			const image = new Image();
			image.src = readerEvent.target.result; // trigger for image.onload
			image.onload = (imageEvent) => this.checkDimension(readerEvent, file, imageEvent)
		}
	}

	onImageUpload = (e) => {
		const files = e.target.files;
		if (files && files[0]) {
			const reader = new FileReader();
			reader.readAsDataURL(files[0]); // trigger for reader.onload
			reader.onload = (readerEvent) => this.onReaderLoad(readerEvent, files[0]);
		}
	}

	onImageDelete = () => {
		const { onDelete } = this.props;

		this.setState({ src: '', width_gte_height: true });

		onDelete()
	}

	clearInputValue = (event) => event.target.value = null;

	render() {
		const {
			id,
			src,
			onChange,
			onDelete,
			onSetMain,
			cropOptions = {},
			starActive = false,
			alt = 'Placeholder Image',
		} = this.props;

		const {
			src: s_src,
			width_gte_height: s_wgh
		} = this.state;

		const imageOptions = { width: '120', height: '120', borderRadius: '0%' };
		if (Object.keys(cropOptions).length > 0 && cropOptions.width && cropOptions.height) {
			const { width, height, cropShape } = cropOptions;
			if (width) imageOptions.width = '' + width;
			if (height) imageOptions.height = '' + height;
			if (cropShape === 'round') imageOptions.borderRadius = '50%';
			cropOptions.aspect = parseInt(imageOptions.width) / parseInt(imageOptions.height);
		}

		return (
			<div className="image-card-container" style={{ width: imageOptions.width, height: imageOptions.height }}>
				<div className="image-card-content" style={{ borderRadius: imageOptions.borderRadius }}>
					<Label for={id}>
						<img
							src={s_src ? s_src : (src ? src : addImage)}
							alt={alt}
							// className={s_wgh ? "img-width" : "img-height"}
							onLoad={this.checkOrientation}
							style={{ borderRadius: imageOptions.borderRadius }}
						/>
					</Label>
					{
						Object.keys(cropOptions).length > 0
						&& <CropImageModal
							ref={(element) => this.cropImageModal = element}
							image={src}
							options={{ ...cropOptions }}
							onSave={onChange}
						/>
					}
					{
						(src || s_src) &&
						<div className="image-card-actions">
							{
								onSetMain &&
								<Button
									// color="warning"
									className={"btn-action btn-star-" + (starActive ? 'active' : 'deactive')}
									onClick={onSetMain}
									block>
									<i className="fa fa-star"></i>
								</Button>
							}
							{
								onDelete &&
								<Button color="danger" className="btn-danger-override btn-action" onClick={this.onImageDelete} block>
									<i className="fa fa-trash"></i>
								</Button>
							}
						</div>
					}
					{
						onChange
						&& <Input type="file" id={id} onInput={this.onImageUpload} onClick={this.clearInputValue} />
					}
				</div>
			</div>
		);
	}
}

export default ImageCard;