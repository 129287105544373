import Login from "pages/Login.js";
import Users from "pages/User/Users.js";
import UserEditForm from "pages/User/EditForm";
import UserCreateForm from "pages/User/CreateForm";
import UserDetails from "pages/User/UserDetails.js";
import Orders from "pages/Order/Orders.js";
import OrderDetails from "pages/Order/OrderDetails.js";
import Products from "pages/Product/Products.js";
import ProductDetails from "pages/Product/ProductDetails.js";
import ProductForm from "pages/Product/ProductForm";
import Transactions from "pages/Transaction/Transactions.js";
import TransactionDetails from "pages/Transaction/TransactionDetails.js";
import Reports from "pages/Reports.js";
import ComingSoon from "pages/ComingSoon.js";
import Dashboard from "pages/Dashboard";
import Imports from "pages/Imports/Imports.js";
import Companies from "pages/Company/Companies";
import EditCompany from "pages/Company/EditCompany";
import CompanyDetails from "pages/Company/CompanyDetails";
import Approvals from "pages/Approvals";
import Runs from "pages/Run/Runs.js";
import RunDetails from "pages/Run/RunDetails.js";
import Corporations from "pages/Corporation/Corporations";
import CorporationDetails from "pages/Corporation/CorporationDetails";
import EditCorporation from "pages/Corporation/EditCorporation";
import Coupons from "pages/Coupons/Coupons";
import CouponDetails from "pages/Coupons/CouponDetails";
import { CouponForm } from "pages/Coupons/CouponForm";
import Transfers from "pages/Transfer/Transfers.js";
import TransferDetails from "pages/Transfer/TransferDetails.js";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/dashboard",
  },
  {
    path: "/approvals",
    name: "Approvals",
    rtlName: "",
    icon: "tim-icons icon-bell-55",
    component: Approvals,
    layout: "/dashboard",
  },
  {
    path: "/runs",
    name: "Runs",
    rtlName: "",
    icon: "tim-icons icon-user-run",
    component: Runs,
    layout: "/dashboard",
  },
  {
    path: "/transactions",
    name: "Transactions",
    rtlName: "",
    icon: "tim-icons icon-money-coins",
    component: Transactions,
    layout: "/dashboard",
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "",
    icon: "tim-icons icon-bag-16",
    component: Orders,
    layout: "/dashboard",
  },
  {
    path: "/transfers",
    name: "Transfers",
    rtlName: "",
    icon: "tim-icons icon-refresh-02",
    component: Transfers,
    layout: "/dashboard",
  },
  {
    path: "/products",
    name: "Products",
    rtlName: "",
    icon: "tim-icons icon-basket-simple",
    component: Products,
    layout: "/dashboard",
  },
  {
    path: "/corporations",
    name: "Corporations",
    rtlName: "",
    icon: "tim-icons icon-bank",
    component: Corporations,
    layout: "/dashboard",
  },
  {
    path: "/companies",
    name: "Companies",
    rtlName: "",
    icon: "tim-icons icon-square-pin",
    component: Companies,
    layout: "/dashboard",
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: Users,
    layout: "/dashboard",
  },
  {
    path: "/reports",
    name: "Reports",
    rtlName: "",
    icon: "tim-icons icon-paper",
    component: ComingSoon,
    layout: "/dashboard",
  },
  {
    path: "/imports",
    name: "Imports",
    rtlName: "",
    icon: "tim-icons icon-upload",
    component: Imports,
    layout: "/dashboard",
  },
  {
    path: "/coupons",
    name: "Coupons",
    rtlName: "",
    icon: "tim-icons icon-tag",
    component: Coupons,
    layout: "/dashboard",
  },
  /** 
  {
    path: "/error-logs",
    name: "Error Logs",
    rtlName: "",
    icon: "tim-icons icon-alert-circle-exc",
    component: ComingSoon,
    layout: "/dashboard"
  },
  */
  /* Invisible routes (not showing in Sidebar) below this line */
  {
    path: "/login",
    name: "Login",
    rtlName: "",
    component: Login,
    layout: "/auth",
    invisible: true,
  },
  {
    path: "/transaction",
    name: "Transaction Details",
    rtlName: "",
    component: TransactionDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/user",
    name: "User Details",
    rtlName: "",
    component: UserDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/user/create",
    name: "Create User",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: UserCreateForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/user/edit",
    name: "Edit User",
    rtlName: "",
    icon: "tim-icons icon-single-02",
    component: UserEditForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/order",
    name: "Order Details",
    rtlName: "",
    component: OrderDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/product",
    name: "Product Details",
    rtlName: "",
    component: ProductDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/product/create",
    name: "Create Product",
    rtlName: "",
    component: ProductForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/product/edit",
    name: "Edit Product",
    rtlName: "",
    component: ProductForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/company",
    name: "Company Details",
    rtlName: "",
    component: CompanyDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/company/edit",
    name: "Edit Company",
    rtlName: "",
    component: EditCompany,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/run",
    name: "Run Details",
    rtlName: "",
    component: RunDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/corporation",
    name: "Corporation Details",
    rtlName: "",
    component: CorporationDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/corporation/edit",
    name: "Edit Corporation",
    rtlName: "",
    component: EditCorporation,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/coupon/edit",
    name: "Edit Coupon",
    rtlName: "",
    icon: "tim-icons icon-upload",
    component: CouponForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/coupon/create",
    name: "Create Coupon",
    rtlName: "",
    icon: "tim-icons icon-upload",
    component: CouponForm,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/coupon",
    name: "Coupon Details",
    rtlName: "",
    icon: "tim-icons icon-upload",
    component: CouponDetails,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/transfer",
    name: "Transfer Details",
    rtlName: "",
    component: TransferDetails,
    layout: "/dashboard",
    invisible: true,
  },
];

export default routes;
