import React from "react";
import StepWizard from "react-step-wizard";
import Papa from "papaparse";
// reactstrap components
import { Col } from "reactstrap";
// Helpers
import { normalizeProductImportResult } from "../../helpers/normalizer";
// Requests
import { handleProductsCSVRequest } from "../../api/import";

// wizard steps
import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";

class Imports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      importType: null,
      isImportCompleted: false,
      productBulkResult: null,
      productBulkSucess: false,
      productBulkLoading: false,
      productBulkReady: false,
      errorProductsAlert: "",
      inputArray: null,
    };
  }

  onChangeImportType = (importType) => {
    this.setState({ importType: importType.value });
  };

  onDataUploaded = (input) => {
    Papa.parse(input.target.files[0], {
      complete: (results) => {
        const normalizedInput = normalizeProductImportResult(results.data)
        this.setState({
          productBulkReady: true,
          inputArray: normalizedInput
        });
      },
    });

    const { importType } = this.state;
    switch (importType) {
      case "products":
        this.setState({ productBulkReady: true });
        break;
      default:
        break;
    }
  };

  onFinishImport = async () => {
    const { importType, inputArray } = this.state;

    switch (importType) {
      case "products":
        try {
          this.setState({
            productBulkLoading: true,
          });

          const { importedProducts, errorProductsMessage } =
            await handleProductsCSVRequest(inputArray);

          this.setState({
            productBulkResult: importedProducts,
            errorProductsAlert: errorProductsMessage,
            productBulkSuccess: true,
            productBulkLoading: false,
          });
        } catch (err) {
          console.log(err);
          this.setState({
            productBulkResult: null,
            productBulkSucess: false,
            productBulkLoading: false,
          });
        }
        break;
      default:
        break;
    }
  };

  render() {
    const {
      isImportCompleted,
      importType,
      productBulkResult,
      productBulkLoading,
      productBulkReady,
      errorProductsAlert,
    } = this.state;

    const steps = [
      {
        stepName: "Type",
        stepIcon: "tim-icons icon-single-copy-04",
        component: Step1,
        stepProps: {
          onChangeImportType: this.onChangeImportType,
        },
      },
      {
        stepName: "Format",
        stepIcon: "tim-icons icon-bullet-list-67",
        component: Step2,
        stepProps: {
          importType,
        },
      },
      {
        stepName: "Upload",
        stepIcon: "tim-icons icon-upload",
        component: Step3,
        stepProps: {
          importType,
          productBulkResult,
          productBulkLoading,
          productBulkReady,
          errorProductsAlert,
          onDataUploaded: this.onDataUploaded,
          onFinishImport: this.onFinishImport
        },
      },
    ];

    return (
      <div className="content">
        <Col className="mr-auto ml-auto" md="10">
          {!isImportCompleted ? (
            <>
              <StepWizard>
                <Step1 {...steps[0].stepProps} />
                <Step2 {...steps[1].stepProps} />
                <Step3 {...steps[2].stepProps} />
              </StepWizard>
            </>
          ) : (
            <div>
              <h3 className="card-title">
                Import has been completed successfully.
              </h3>
              <button
                onClick={() => this.setState({ isImportCompleted: false })}
                className="btn btn-wd text-center btn-info"
              >
                Import Another File
              </button>
            </div>
          )}
        </Col>
      </div>
    );
  }
}

export default Imports;
