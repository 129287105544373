import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";
import moment from 'moment';
import { Link } from "react-router-dom";

import { getOrderRequest } from 'api/order';
import { formatPrice } from "helpers/pricing";
import { formatOrderStatus } from "helpers/status";

class OrderDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      order: null
    }
  }

  async componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get('id');

    if (id) {
      const order = await getOrderRequest(id);
      this.setState({ order })
    } else {
      this.goTo('/orders')
    }
  }

  goTo = path => this.props.history.push(path);

  render() {

    const {
      order = {}
    } = this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="mb-5">
                  <CardTitle tag="h3">
                    Order Details
                  </CardTitle>
                  <Button
                    className="float-right"
                    color="default"
                    onClick={() => this.goTo('/orders')}
                  >
                    Back
                  </Button>
                </CardHeader>
                <CardBody>
                  {!order
                    ? <div>Loading...</div>
                    : <>
                      <div className="typography-line">
                        <h4>
                          <span>ID</span>
                          {order._id}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Client Company</span>
                          {order.clientCompany ? <Link to={`/company?id=${order.clientCompany._id}`}>{order.clientCompany.name}</Link> : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Seller Company</span>
                          {order.sellerCompany ? <Link to={`/company?id=${order.sellerCompany._id}`}>{order.sellerCompany.name}</Link> : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Ordered By</span>
                          {order.client ? <Link to={`/user?id=${order.client.user}`}>{order.client.firstName} {order.client.lastName}</Link> : "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Status</span>
                          {formatOrderStatus(order.status)}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Payment Intent Id</span>
                          {order.paymentIntentId || "N/A"}
                        </h4>
                      </div>

                      <div className="typography-line">
                        <h4>
                          <span>Created At</span>
                          {order.createdAt ? moment(order.createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                        </h4>
                      </div>
                      <div className="typography-line">
                        <h4>
                          <span>Updated At</span>
                          {order.updatedAt ? moment(order.updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                        </h4>
                      </div>

                      <Card className="detail-card">
                        <CardHeader>
                          <h3>Products</h3>
                        </CardHeader>
                        <CardBody>
                          <Table striped hover>
                            <thead>
                              <tr>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Notes</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.products && order.products.length > 0 &&
                                order.products.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.product ?.sku || 'N/A'}</td>
                                      <td>{item.product ?.name || 'N/A'}</td>
                                      <td>${formatPrice(item.discountedPrice ? item.discountedPrice : item.price ? item.price : 0)}</td>
                                      <td>{item.quantity || 'N/A'}</td>
                                      <td>{item.notes || 'N/A'}</td>
                                      <td>${formatPrice((item.price * item.quantity) || 0)}</td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>

                      <Card className="detail-card">
                        <CardHeader>
                          <h3>Amount Breakdown</h3>
                        </CardHeader>
                        <CardBody>
                          <div className="typography-line">
                            <h4>
                              <span>Subtotal</span>
                              $ {formatPrice(order.subtotal || 0)}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Taxes</span>
                              $ {formatPrice(order.taxes || 0)}
                            </h4>
                          </div>
                          
                          <div className="typography-line">
                            <h4>
                              <span>Delivery Fee</span>
                              $ {formatPrice(order.deliveryFee || 0)}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Other Fees</span>
                              $ {formatPrice(order.otherFees || 0)}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Discount</span>
                              {order.discount?.type === "Percentage" ? `${order.discount.value}% (COUPON: ${order.discount.coupon?.code || 'N/A'})` : order.discount?.type === "Fixed" ? `$ ${formatPrice(order.discount.value || 0)} (COUPON: ${order.discount.coupon?.code || 'N/A'})` : "$ 0.00"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Total</span>
                              $ {formatPrice(order.total)}
                            </h4>
                          </div>
                        </CardBody>
                      </Card>

                      <Card className="detail-card">
                        <CardHeader>
                          <h3>Delivery Address</h3>
                        </CardHeader>
                        <CardBody>
                          <div className="typography-line">
                            <h4>
                              <span>Street Address</span>
                              {order?.deliveryAddress?.streetAddress || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>City</span>
                              {order?.deliveryAddress?.city || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>State</span>
                              {order?.deliveryAddress?.state || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Zip Code</span>
                              {order?.deliveryAddress?.zipCode || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Notes</span>
                              {order?.deliveryAddress?.notes || 'N/A'}
                            </h4>
                          </div>
                        </CardBody>
                      </Card>

                      <Card className="detail-card">
                        <CardHeader>
                          <h3>Pickup Address</h3>
                        </CardHeader>
                        <CardBody>
                          <div className="typography-line">
                            <h4>
                              <span>Street Address</span>
                              {order?.pickupAddress?.streetAddress || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>City</span>
                              {order?.pickupAddress?.city || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>State</span>
                              {order?.pickupAddress?.state || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Zip Code</span>
                              {order?.pickupAddress?.zipCode || 'N/A'}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Notes</span>
                              {order?.pickupAddress?.notes || 'N/A'}
                            </h4>
                          </div>
                        </CardBody>
                      </Card>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default OrderDetails;
