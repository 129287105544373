import { apiRequest} from './index'

export const getUsersRequest = (page, limit, search, role='', company='') => {
  return apiRequest(
    `/admin/users?page=${page}&limit=${limit}&search=${search}&role=${role}&company=${company}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getUserRequest = (id) => {
  return apiRequest(
    `/admin/users/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const updateUserRequest = (user) => {
  return apiRequest(
    `/admin/users/${user._id}`,
    {
			method: 'PUT',
			body: JSON.stringify(user)
    },
    true
  )
}

export const createUserRequest = (user) => {
  return apiRequest(
    `/admin/users`,
    {
			method: 'POST',
			body: JSON.stringify(user)
    },
    true
  )
}

export const deleteUserRequest = (userId) => {
  return apiRequest(
    `/admin/users/${userId}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const getUserStatsRequest = () => {
  return apiRequest(
    `/admin/user-stats`,
    {
      method: 'GET'
    },
    true
  )
}