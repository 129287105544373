import React from "react";
import Async from 'react-select/async';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
  FormGroup
} from "reactstrap";
// Components
import PaginationComponent from "components/Custom/Pagination";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
// Helpers
import { calcStartEndPage } from 'helpers/pagination';
import { formatProductStatus } from "helpers/status";
// Requests
import { getProductsRequest, getProductsStatsRequest, deleteProductRequest } from 'api/product';
// Assets
import productPlaceholder from 'assets/img/product-placeholder.jpg';

class Products extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: null,
            page: 0,
            total: 0,
            limit: 25,
            pages: 0,
            current_page: 0,
            start_page: 0,
            end_page: 9,
            first_page: 0,
            last_page: 0,
            stats: null,
            search: '',
            deleteProductId: '',
            status: ''
        }
        this.timeout = null;
    }

    async componentDidMount() {
        await this.getProducts();
        await this.getStats();
    }

    getProducts = async () => {        
        const { limit, page, search, status, company } = this.state;
        const productsObj = await getProductsRequest(page, limit, search, company && company.value, status);
        const products = productsObj.products;
        const total = productsObj.total;
        const pages = Math.ceil(total / limit);
        const last_page = pages - 1;
        const end_page = pages > 10 ? 9 : pages - 1;

        this.setState({
            products,
            total,
            pages,
            last_page,
            end_page
        })
    }

    getStats = async () => {
        const stats = await getProductsStatsRequest();
        this.setState({
            stats
        })
    }
  
    goTo = path => this.props.history.push(path);

    goToPage = async (page) => {
        const { limit, pages, search } = this.state;
        const productsObj = await getProductsRequest(page, limit, search);
        const products = productsObj.products;
        const { start_page, end_page } = calcStartEndPage(page, pages);

        this.setState({
            products: products,
            current_page: page,
            start_page: start_page,
            end_page: end_page
        })
    }
    
    onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getProducts();
		}, 300);
    }
    
    deleteProduct = async () => {
		const { deleteProductId } = this.state;
        await deleteProductRequest(deleteProductId);
        this.getProducts();
	}

	openConfirmation = (productId) => () => {
		this.setState({
			deleteProductId: productId
		});
		this.deleteConfirmationModal.toggle();
	}

    onSelectChange = (value) => {
		this.onChangeFilter({ target: { value: value, name: 'company' } });
	}

    render() {

        const {
            products,
            pages,
            current_page,
            start_page,
            end_page,
            stats,
            search,
            status
        } = this.state;

        const totalProducts = stats ? stats.productCount : 0;
        const availableProducts = stats ? stats.availableProductCount : 0;

        return (
            <>
            <div className="content">
            <Row>
                <Col xs="12">
                    <Card className="card-chart">
                        <CardHeader>
                            <Row>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-bag-16" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Products</p>
                                                <CardTitle tag="h3">{totalProducts}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated Now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                                <Col lg="6" md="6">
                                    <Card className="card-stats">
                                        <CardBody>
                                        <Row>
                                            <Col xs="5">
                                            <div className="info-icon text-center icon-warning">
                                                <i className="tim-icons icon-check-2" />
                                            </div>
                                            </Col>
                                            <Col xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Available Products</p>
                                                <CardTitle tag="h3">{availableProducts}</CardTitle>
                                            </div>
                                            </Col>
                                        </Row>
                                        </CardBody>
                                        <CardFooter>
                                        <hr />
                                        <div className="stats">
                                            <i className="tim-icons icon-refresh-01" /> Updated Now
                                        </div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                <Card>
                    <CardHeader>
                        <CardTitle tag="h4" className="d-inline-block">
                            Products
                        </CardTitle>
                        <Button className="pull-right mb-3" color="primary" onClick={() => this.goTo('/product/create')}>Add Product</Button>
                        <FormGroup>
                            <Input placeholder="Search Products..." name="search" value={search} type="text" onChange={this.onChangeFilter}></Input>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="select"
                                name="status"
                                value={status}
                                onChange={this.onChangeFilter}
                            >
                                <option value={''}>-- SELECT STATUS --</option>
                                <option value='pending'>Pending</option>
                                <option value='created'>Created</option>
                                <option value='available'>Available</option>
                                <option value='withdrawn'>Withdrawn</option>
                            </Input>
                        </FormGroup>
                    </CardHeader>
                    <CardBody>
                    <Table responsive>
                        <thead className="text-primary">
                        <tr>
                            <th>SKU</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th className="text-right">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {products && products.length
                        ? products.map((product) => {
                            return (
                                <tr key={product._id}>
                                    <td>{product.sku || "N/A"}</td>
                                    <td>{product.images && product.images.length > 0
                                            ? <a href={product.images[0]} target="_blank" rel="noreferrer"><img className="product-preview" src={product.images[0]} /></a> 
                                            : <img className="product-preview" src={productPlaceholder} />
                                        }
                                    </td>
                                    <td>{product.name || "N/A"}</td>
                                    <td>{product.status ? formatProductStatus(product.status) : "N/A"}</td>
                                    <td className="text-right">
                                        <Button
                                            className="btn-link btn-icon"
                                            color="success"
                                            id={`tooltip-view-${product._id}`}
                                            size="sm"
                                            onClick={() => this.goTo(`/product?id=${product._id}`)}
                                        >
                                            <i className="tim-icons icon-zoom-split" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-view-${product._id}`}
                                        >
                                            View
                                        </UncontrolledTooltip>
                                        <Button
                                            className="btn-link btn-icon"
                                            color="info"
                                            id={`tooltip-edit-${product._id}`}
                                            size="sm"
                                            onClick={() => this.goTo(`/product/edit?id=${product._id}`)}
                                        >
                                            <i className="tim-icons icon-pencil" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-edit-${product._id}`}
                                        >
                                            Edit
                                        </UncontrolledTooltip>
                                        <Button
                                            className="btn-link btn-icon"
                                            color="danger"
                                            id={`tooltip-delete-${product._id}`}
                                            size="sm"
                                            onClick={this.openConfirmation(product._id)}
                                        >
                                            <i className="tim-icons icon-simple-remove" />
                                        </Button>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target={`tooltip-delete-${product._id}`}
                                        >
                                            Delete
                                        </UncontrolledTooltip>
                                    </td>
                                </tr>
                            )
                        })
                        :   <tr>
                                <td colSpan="6">There's no product in the database</td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    {products?.length > 0 && 
                        <PaginationComponent 
                            pages={pages}
                            current_page={current_page}
                            start_page={start_page}
                            end_page={end_page}
                            goToPage={this.goToPage}
                        />
                    }
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <ConfirmationModal
                ref={el => this.deleteConfirmationModal = el}
                title="Delete Product?"
                buttons={[
                    { title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
                    { title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteProduct }
                ]}
            >
                Are you sure you want to delete this product?
            </ConfirmationModal>
            </div>
        </>
        );
    }
}

export default Products;
