import React from "react";

// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	CardTitle,
	Row,
	Col,
	Table,
	UncontrolledTooltip,
	Input,
	FormGroup
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import Async from 'react-select/async';

import PaginationComponent from "components/Custom/Pagination";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { calcStartEndPage } from 'helpers/pagination';
import { formatPrice } from "helpers/pricing";
import { formatOrderStatus } from "helpers/status";
import { getOrdersRequest, getOrdersStatsRequest, deleteOrderRequest } from 'api/order';
import { getCompaniesRequest } from "api/company";
import { runsStatus } from "constants/status";
import { formatRunStatus } from "helpers/status";

class Orders extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			orders: null,
			page: 0,
			total: 0,
			limit: 25,
			pages: 0,
			current_page: 0,
			start_page: 0,
			end_page: 9,
			first_page: 0,
			last_page: 0,
			stats: null,
			search: '',
			deleteOrderId: '',
			status: '',
			client: ''
		}
		this.timeout = null;
	}

	async componentDidMount() {
		await this.getOrders();
		await this.getStats();
	}

	getOrders = async () => {
		const { limit, page, search, status, clientCompany, sellerCompany } = this.state;
		const ordersObj = await getOrdersRequest(page, limit, search, status, sellerCompany && sellerCompany.value || '', clientCompany && clientCompany.value || '');
		const orders = ordersObj.orders;
		const total = ordersObj.total;
		const pages = Math.ceil(total / limit);
		const last_page = pages - 1;
		const end_page = pages > 10 ? 9 : pages - 1;

		this.setState({
			orders,
			total,
			pages,
			last_page,
			end_page
		})
	}

	getStats = async () => {
		const stats = await getOrdersStatsRequest();
		this.setState({
			stats
		})
	}

	goTo = path => this.props.history.push(path);

	goToPage = async (page) => {
		const { limit, pages, search } = this.state;
		const ordersObj = await getOrdersRequest(page, limit, search);
		const orders = ordersObj.orders;
		const { start_page, end_page } = calcStartEndPage(page, pages);

		this.setState({
			orders: orders,
			current_page: page,
			start_page: start_page,
			end_page: end_page
		})
	}

	onChangeFilter = (event) => {

		const { name, value } = event.target;
		this.setState({
			[name]: value,
			page: 0
		})
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.timeout = setTimeout(() => {
			this.getOrders();
		}, 300);
	}

	deleteOrder = async () => {
		const { deleteOrderId } = this.state;
		await deleteOrderRequest(deleteOrderId);
		this.getOrders();
	}

	openConfirmation = (orderId) => () => {
		this.setState({
			deleteOrderId: orderId
		});
		this.deleteConfirmationModal.toggle();
	}

	loadCompanies = (role) => async (text, callback) => {
		if(text) {
			const companiesResponse = await getCompaniesRequest(0, 0, text, role, '');
			const companies = companiesResponse.companies.map(company => {
				return {
					data: company,
					label: company.name,
					value: company._id
				}
			})
			callback(companies);
		}
	}

	onSelectChange = (key) => (value) => {
		this.onChangeFilter({ target: { value: value, name: key } });
	}
	
	render() {

		const {
			orders,
			pages,
			current_page,
			start_page,
			end_page,
			stats,
			search,
			status,
			client,
			clientCompany,
			sellerCompany
		} = this.state;

		const totalOrders = stats ? stats.orderCount : 0;
		const totalOpenOrders = stats ? stats.openOrdersCount : 0;

		return (
			<>
				<div className="content">
					<Row>
						<Col xs="12">
							<Card className="card-chart">
								<CardHeader>
									<Row>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-bag-16" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Total Orders</p>
																<CardTitle tag="h3">{totalOrders}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
												</CardFooter>
											</Card>
										</Col>
										<Col lg="6" md="6">
											<Card className="card-stats">
												<CardBody>
													<Row>
														<Col xs="5">
															<div className="info-icon text-center icon-warning">
																<i className="tim-icons icon-money-coins" />
															</div>
														</Col>
														<Col xs="7">
															<div className="numbers">
																<p className="card-category">Open Orders</p>
																<CardTitle tag="h3">{totalOpenOrders}</CardTitle>
															</div>
														</Col>
													</Row>
												</CardBody>
												<CardFooter>
													<hr />
													<div className="stats">
														<i className="tim-icons icon-refresh-01" /> Updated now
                                        </div>
												</CardFooter>
											</Card>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Orders</CardTitle>
									<FormGroup>
										<Input
											type="select"
											name="status"
											value={status}
											onChange={this.onChangeFilter}
										>
											<option value={''}>-- SELECT STATUS --</option>
											{runsStatus.map(status => (
												<option value={status}>{formatRunStatus(status)}</option>
											))}
										</Input>
									</FormGroup>
									<FormGroup>
										<Async
											value={clientCompany}
											name="clientCompany"
											options={[]}
											loadOptions={this.loadCompanies('client')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('clientCompany')}
											isClearable
											placeholder="Select Client Company..."
										/>
									</FormGroup>
									<FormGroup>
										<Async
											value={sellerCompany}
											name="sellerCompany"
											options={[]}
											loadOptions={this.loadCompanies('seller')}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={this.onSelectChange('sellerCompany')}
											isClearable
											placeholder="Select Seller Company..."
										/>
									</FormGroup>
								</CardHeader>
								<CardBody>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th>Date</th>
												<th>Client</th>
												<th>Seller</th>
												<th>Products</th>
												<th>Status</th>
												<th>Total</th>
												<th className="text-right">Actions</th>
											</tr>
										</thead>
										<tbody>
											{orders && orders.length
												? orders.map((order) => {
													return (
														<tr key={order._id}>
															<td>{moment(order.createdAt).format('DD/MM/YYYY')}</td>
															<td>{order.clientCompany 
																? <Link to={`/company?id=${order.clientCompany._id}`}>{order.clientCompany.name}</Link>
																: "N/A"
															}</td>
															<td>{order.sellerCompany 
																? <Link to={`/company?id=${order.sellerCompany._id}`}>{order.sellerCompany.name}</Link>
																: "N/A"
															}</td>
															<td>{order.products && order.products.length}</td>
															<td>{formatOrderStatus(order.status)}</td>
															<td>$ {formatPrice(order.total)}</td>
															<td className="text-right">
																<Button
																	className="btn-link btn-icon"
																	color="success"
																	id={`tooltip-view-${order._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/order?id=${order._id}`)}
																>
																	<i className="tim-icons icon-zoom-split" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-view-${order._id}`}
																>
																	View
                                								</UncontrolledTooltip>
																{/* <Button
																	className="btn-link btn-icon"
																	color="info"
																	id={`tooltip-edit-${order._id}`}
																	size="sm"
																	onClick={() => this.goTo(`/order/edit?id=${order._id}`)}
																>
																	<i className="tim-icons icon-pencil" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-edit-${order._id}`}
																>
																	Editar
                                								</UncontrolledTooltip> */}
																<Button
																	className="btn-link btn-icon"
																	color="danger"
																	id={`tooltip-delete-${order._id}`}
																	size="sm"
																	onClick={this.openConfirmation(order._id)}
																>
																	<i className="tim-icons icon-simple-remove" />
																</Button>
																<UncontrolledTooltip
																	delay={0}
																	target={`tooltip-delete-${order._id}`}
																>
																	Delete
                                								</UncontrolledTooltip>
															</td>
														</tr>
													)
												})
												: <tr>
													<td colSpan="7">There are no orders to show</td>
												</tr>
											}
										</tbody>
									</Table>
									{orders ?.length > 0 &&
										<PaginationComponent
											pages={pages}
											current_page={current_page}
											start_page={start_page}
											end_page={end_page}
											goToPage={this.goToPage}
										/>
                    }
								</CardBody>
							</Card>
						</Col>
					</Row>
					<ConfirmationModal
						ref={el => this.deleteConfirmationModal = el}
						title="Delete Order?"
						buttons={[
							{ title: 'Cancel', className: 'btn-primary-override btn btn-primary' },
							{ title: 'Delete', className: 'btn-danger-override btn btn-danger', onClick: this.deleteOrder }
						]}
					>
						Are you sure you want to delete the order?
        </ConfirmationModal>
				</div>
			</>
		);
	}
}

export default Orders;
