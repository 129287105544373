import React from "react";

// reactstrap components
import { FormGroup, Row, Col, Alert, Button, CustomInput } from "reactstrap";
class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step3Select: null,
    };
  }

  render() {
    const {
      productBulkResult,
      productBulkLoading,
      productBulkReady,
      errorProductsAlert,
      onDataUploaded,
      onFinishImport,
    } = this.props;

    return (
      <>
        <form>
          <h5 className="info-text">Upload your csv</h5>
          <Row className="justify-content-center">
            <Col sm="12">
              <FormGroup className="d-flex justify-content-around align-items-center flex-column">
                <CustomInput
                  type="file"
                  id="exampleCustomFileBrowser"
                  name="customFile"
                  onChange={onDataUploaded}
                  label={"Click here to select your csv file"}
                  accept=".csv"
                />

                {productBulkReady && !productBulkResult && (
                  <Row>
                    <Col md={12}>
                      <div>CSV received! Click finish to create instances.</div>
                    </Col>
                  </Row>
                )}
                {productBulkLoading && (
                  <Row>
                    <Col md={12}>
                      <div>Creating instances...</div>
                    </Col>
                  </Row>
                )}
                {!productBulkLoading && productBulkResult && (
                  <Row>
                    <Col md={12}>
                      <div>Products created successfully</div>
                    </Col>
                  </Row>
                )}
                {errorProductsAlert && (
                  <Row className="mt-2">
                    <Col md={12}>
                      <Alert color="danger">{errorProductsAlert}</Alert>
                    </Col>
                  </Row>
                )}
              </FormGroup>
              <Row className="d-flex justify-content-lg-between">
                <Button onClick={this.props.previousStep}>Previous Step</Button>
                <Button 
                  color="primary" 
                  onClick={onFinishImport}
                  disabled={!productBulkReady || productBulkResult}
                >
                  Upload
                </Button>
              </Row>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}

export default Reports;
