import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';
import { getCouponRequest } from "api/coupons";

class CouponDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          coupon: null
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');

        if (id) {
            const coupon = await getCouponRequest(id);
            this.setState({ coupon })
        } else {
            this.goTo('/coupons')
        }
    }
  
    goTo = path => this.props.history.push(path);

    render() {

        const {
					coupon={}
        } = this.state;

        const {
            _id,
            name,
						code,
						description,
            discountType,
            discountValue,
            startDate,
            endDate,
            createdAt,
            updatedAt,
            
        } = coupon || {};

        return (
            <>
            <div className="content">
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader className="mb-5">
                      <CardTitle tag="h3">
                        Coupon Details
                      </CardTitle>
                      <Button 
                        className="float-right"
                        color="default"
                        onClick={() => this.goTo('/coupons')}
                      >
                        Back
                      </Button>
                    </CardHeader>
                    <CardBody>
                      {!coupon 
                      ? <div>Loading...</div>
                      : <>
                          <div className="typography-line">
                            <h4>
                              <span>ID</span>
                              {_id}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Name</span>
                              {name}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Code</span>
                              {code}
                            </h4>
                          </div>

													<div className="typography-line">
                            <h4>
                              <span>Description</span>
                              {description || "N/A"}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Discount Type</span>
                              {discountType}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Discount Value</span>
                              {discountValue}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Valid From</span>
                              {startDate}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Valid To</span>
                              {endDate}
                            </h4>
                          </div>
                          <div className="typography-line">
                            <h4>
                              <span>Created At</span>
                              {createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>

                          <div className="typography-line">
                            <h4>
                              <span>Updated At</span>
                              {updatedAt ? moment(updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                            </h4>
                          </div>
                        </>
                      }
                      
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        );
    }
}

export default CouponDetails;
