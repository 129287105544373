import { apiRequest} from './index'

export const signInRequest = (data) => {
    return apiRequest(
      `/auth/signin-admin`,
      {
        method: 'POST',
        body: JSON.stringify(data)
      },
      true
    )
}
