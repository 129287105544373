export const normalizeProductImportResult = (inputArray) => {

	if (inputArray.length === 0) return [];

    const keys = inputArray[0];
    const outputArray = [];

    for (let i = 1; i < inputArray.length; i++) {
        const currentArray = inputArray[i];
        const obj = {};
        
        for (let j = 0; j < keys.length; j++) {
            obj[keys[j]] = currentArray[j];
        }

        outputArray.push(obj);
    }

    return outputArray;
}