import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth/Auth.js";
import AdminLayout from "layouts/Admin/Admin.js";

import "assets/css/nucleo-icons.css";
import "react-notification-alert/dist/animate.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";
import "assets/demo/demo.css";
import "assets/css/custom.css";
import { getCredentials } from "./helpers/auth";

const hist = createBrowserHistory();

function isSignedIn() {
  const signedIn = getCredentials();
  return signedIn && signedIn._id;
}

function isEmailVerified() {
  // TODO: Change this verification to isRoleAdmin
  // const signedIn = getCredentials();
  // return signedIn && signedIn.email_verified;
  return true;
}

const routePaths = [
  "/approvals",
  "/runs",
  "/run",
  "/transactions",
  "/transaction",
  "/users",
  "/user",
  "/orders",
  "/order",
  "/products",
  "/product",
  "/product/edit",
  "/product/create",
  "/reports",
  "/imports",
  "/error-logs",
  "/dashboard",
  "/companies",
  "/company",
  "/corporations",
  "/corporation",
  "/coupons",
  "/coupon",
  "/transfers",
  "/transfer"
];

class App extends React.Component {
  render() {
    return (
      <Router history={hist}>
        <Switch>
          <Route
            path={["/login", "/access-denied"]}
            render={(props) => <AuthLayout {...props} />}
          />
          <Route
            path={routePaths}
            render={(props) =>
              isSignedIn() ? (
                isEmailVerified() ? (
                  <AdminLayout {...props} />
                ) : (
                  <Redirect to="/access-denied" />
                )
              ) : (
                <Redirect to="/login" />
              )
            }
          />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
