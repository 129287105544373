import { apiRequest} from './index'

export const getTransfersRequest = (page, limit, search, status, corporation, originCompany, destinationCompany) => {
  return apiRequest(
    `/admin/transfers?page=${page}&limit=${limit}&search=${search}&status=${status}&corporation=${corporation}&originCompany=${originCompany}&destinationCompany=${destinationCompany}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getTransferByIdRequest = (id) => {
  return apiRequest(
    `/admin/transfers/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const deleteTransferRequest = (id) => {
    return apiRequest(
      `/admin/transfers/${id}`,
      {
        method: 'DELETE'
      },
      true
    )
  }
  
  export const getTransfersStatsRequest = () => {
    return apiRequest(
      `/admin/transfer-stats`,
      {
        method: 'GET'
      },
      true
    )
  }