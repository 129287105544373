import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import Datetime from "react-datetime";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { getCouponRequest } from "api/coupons";
import { updateCouponRequest } from "api/coupons";
import { createCouponRequest } from "api/coupons";

export const CouponForm = () => {
  const [coupon, setCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const couponId = params.get("id");
  useEffect(() => {
    const fetchCoupon = async () => {
      const coupon = await getCouponRequest(couponId);
      setCoupon(coupon);
    };
    if (couponId) {
      fetchCoupon();
    }
  }, [couponId]);

  const onChangeFormField = (event) => {
    const { name, value } = event.target;
    setCoupon((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSave = async () => {
		coupon.startDate = moment(coupon.startDate).format('MM/DD/YYYY');
		coupon.endDate = moment(coupon.endDate).format('MM/DD/YYYY');
		setLoading(true);
    try {
      if (couponId) {
        await updateCouponRequest(coupon);
      } else {
        await createCouponRequest(coupon);
      }
      setLoading(false);
      history.push("/coupons");
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader className="mb-4 d-flex justify-content-between">
                <CardTitle tag="h3">
                  {couponId ? "Edit" : "Create"} Coupon
                </CardTitle>
                <Button
                  color="default"
                  onClick={() => history.push("/coupons")}
                >
                  Back
                </Button>
              </CardHeader>
              <CardBody>
                {couponId && !coupon ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <FormGroup>
                      <Label>Name <span className="required-start">*</span></Label>
                      <Input
                        type="text"
                        name="name"
                        value={coupon?.name || ""}
                        onChange={onChangeFormField}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Code <span className="required-start">*</span></Label>
                      <Input
                        type="text"
                        name="code"
                        value={coupon?.code || ""}
                        onChange={onChangeFormField}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Description</Label>
                      <Input
                        type="text"
                        name="description"
                        value={coupon?.description || ""}
                        onChange={onChangeFormField}
                      />
                    </FormGroup>
										<FormGroup>
                      <Label>Discount Type <span className="required-start">*</span></Label>
                      <Input
                        type="select"
                        name="discountType"
                        value={coupon?.discountType || ""}
                        onChange={onChangeFormField}
                      >
                        <option value="" selected>Select</option>
												<option value="Percentage">Percentage</option>
												<option value="Fixed">Fixed</option>
											</Input>
                    </FormGroup>
										<FormGroup>
                      <Label>Discount Value <span className="required-start">*</span></Label>
                      <Input
                        type="text"
                        name="discountValue"
                        value={coupon?.discountValue || ""}
                        onChange={onChangeFormField}
                      />
                      {coupon?.discountType === "Percentage"
                        ? <span className="form-text">
                            {`Type percentage with only full numbers and no symbols. Eg: Type "20" for 20% discount.`}
                          </span>
                        : coupon?.discountType === "Fixed"
                          ? <span className="form-text">
                              {`Type value with only full numbers and no symbols. Eg: Type "15" for $15 discount.`}
                            </span>
                          : null
                      }
                      
                    </FormGroup>
										<FormGroup>
											<Label>Valid From <span className="required-start">*</span></Label>
											<Datetime
												inputProps={{
													className: "form-control",
													placeholder: "Start Date",
													name: "startDate"
												}}
												timeFormat={false}
												value={typeof coupon?.startDate === 'string' ? moment(coupon?.startDate) : coupon?.startDate}
												onChange={(date) => onChangeFormField({target: { value: date, name: "startDate" }})}
												closeOnSelect={true}
											/>
										</FormGroup>
										<FormGroup>
											<Label>Valid To <span className="required-start">*</span></Label>
											<Datetime
												inputProps={{
													className: "form-control",
													placeholder: "End Date",
													name: "endDate"
												}}
												timeFormat={false}
												value={typeof coupon?.endDate === 'string' ? moment(coupon?.endDate) : coupon?.endDate}
												onChange={(date) => onChangeFormField({target: { value: date, name: "endDate" }})}
												closeOnSelect={true}
											/>
										</FormGroup>
                    {couponId ? (
                      <Button
                        color="primary"
                        disabled={loading}
                        onClick={onSave}
                      >
                        {loading ? "Processing..." : "Save Changes"}
                      </Button>
                    ) : (
											<Button
												color="primary"
												disabled={loading}
												onClick={onSave}
											>
												{loading ? "Processing..." : "Create Coupon"}
											</Button>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
