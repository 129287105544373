import { getProductsRequest } from 'api/product';
import React, { PureComponent } from 'react';
import Async from 'react-select/async';
import productPlaceholder from 'assets/img/product-placeholder.jpg';

import { FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input } from 'reactstrap';

export default class ProductStoreFormModal extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
            formProduct: null,
            selectedProduct: null
		}
	}


    componentDidUpdate(prevProps) {
        if(this.props.editProduct !== prevProps.editProduct) {
            this.setState({
                formProduct: this.props.editProduct
            })
        }
        
    }

    onChangeField = (e) => {
		const { target } = e;
		const { name, value } = target;
        console.log({name, value})
		this.setState(prevState => ({
			formProduct: {
				...prevState.formProduct,
				[name]: value
			},
		}));
	}

	onChangeCheckbox = (e) => {
		const { target } = e;
		const { name, checked } = target;

		this.setState(prevState => ({
			formProduct: {
				...prevState.formProduct,
				[name]: checked,
                comparePrice: 0
			},
		}));
	}

    onSelectChange = (key) => (value) => {
		this.setState({
			[key]: value
		});
	}

    loadProducts = async (text, callback) => {
		if(text) {
			const productsResponse = await getProductsRequest(0, 0, text, '', '');
			const products = productsResponse.products.map(product => {
				return {
					data: product,
					label: 
					<div>
						<img 
							className="mr-3" 
							height="30px" 
							width="30px" 
                            alt={product?.name}
							src={product.images[0] ? product.images[0] : productPlaceholder}>
						</img>
							{product.name}
					</div>,
					value: product._id
				}
			});
			callback(products);
		}
	}



	onSave = () => {
		const { 
            formProduct,
            selectedProduct
		} = this.state;

		const { 
			onSave,
            isCreateModal
		} = this.props;

        if (isCreateModal) {
            onSave({...selectedProduct?.data, ...formProduct})
            return
        }

        onSave(formProduct)
	}    

	render() {

		const { 
            formProduct,
            selectedProduct
		} = this.state;

		const {
			isLoading,
            isCreateModal,
            isOpen,
            closeModal,
            error
		} = this.props;


		return (
			<Modal isOpen={isOpen} toggle={closeModal}>
				<ModalHeader>{isCreateModal ? 'Add Company Product' : 'Update Company Product'}</ModalHeader>
				<ModalBody>
                {
                            isCreateModal &&
                            <>
                                <FormGroup>
                                    <Label>Select Product</Label>
                                    <Async
                                        value={selectedProduct}
                                        name="selectedProduct"
                                        options={[]}
                                        loadOptions={this.loadProducts}
                                        className="basic-multi-select light-async-select"
                                        classNamePrefix="select"
                                        onChange={this.onSelectChange('selectedProduct')}
                                        isClearable
                                        placeholder="Start typing to load products..."
                                    />
                                    {
                                        selectedProduct &&
                                        <a target="_blank" href={`/product?id=${selectedProduct.value}`} rel="noreferrer">Click here to see more information about the product</a>
                                    }
                                </FormGroup>
                                <FormGroup>
                                <Label>Store SKU</Label>
                                <Input
                                    type="text"
                                    name="storeSku"
                                    value={formProduct?.storeSku || ''}
                                    onChange={this.onChangeField}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#525f7f'
                                    }}
                                />
                            </FormGroup>
                            </>
                        }
                        
                        <FormGroup>
                            <Label>Quantity</Label>
                            <Input
                                type="text"
                                name="quantity"
                                value={formProduct?.quantity || ''}
                                onChange={this.onChangeField}
                                style={{
                                    backgroundColor: 'white',
                                    color: '#525f7f'
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Price</Label>
                            <Input
                                type="text"
                                name="price"
                                value={formProduct?.price || ''}
                                onChange={this.onChangeField}
                                style={{
                                    backgroundColor: 'white',
                                    color: '#525f7f'
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="form-check">
                                <label className="form-check-label" >
                                        <input 
                                            className="form-check-input"
                                            onChange={this.onChangeCheckbox} 
                                            name="hasDiscount"
                                            type="checkbox"
                                            checked={!!formProduct?.hasDiscount}
                                        />
                                            Has Discount
                                        <span className="form-check-sign">
                                                <span className="check"></span>
                                        </span>
                                </label>
                            </div>
                        </FormGroup> 
                        {
                            !!formProduct?.hasDiscount && 
                            <FormGroup>
                                <Label>Original price</Label>
                                <Input
                                    type="text"
                                    name="comparePrice"
                                    value={formProduct?.comparePrice || ''}
                                    onChange={this.onChangeField}
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#525f7f'
                                    }}
                                />
                            </FormGroup>
                        }
                        {
                            error &&
                            <Alert color="danger">
                                {error}
                            </Alert>
                        }
				</ModalBody>
                <ModalFooter className="justify-content-end">
                    <Button 
                        color="primary" 
                        className="btn-success-override mr-2"
                        style={{}} 
                        disabled={!formProduct || isLoading}
                        onClick={this.onSave}
                    >
                        {isLoading ? "Saving" : "Save"}
                    </Button>
                    <Button color="secondary" className="btn-danger-override" onClick={closeModal}>Cancel</Button>
                </ModalFooter>
			</Modal>
		)
	}
}