import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  Input,
  FormGroup,
} from "reactstrap";
import moment from "moment";

import PaginationComponent from "components/Custom/Pagination";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import { calcStartEndPage } from "helpers/pagination";
import { getCouponsRequest, deleteCouponRequest } from "api/coupons";

class Coupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: null,
      page: 0,
      total: 0,
      limit: 25,
      pages: 0,
      current_page: 0,
      start_page: 0,
      end_page: 9,
      first_page: 0,
      last_page: 0,
      stats: null,
      search: "",
      deleteCouponId: "",
      status: "",
      client: "",
    };
    this.timeout = null;
  }

  async componentDidMount() {
    await this.getCoupons();
  }

  getCoupons = async () => {
    const { limit, page, search } = this.state;
    const couponsObj = await getCouponsRequest(page, limit, search);
    const coupons = couponsObj.coupons;
    const total = couponsObj.total;
    const pages = Math.ceil(total / limit);
    const last_page = pages - 1;
    const end_page = pages > 10 ? 9 : pages - 1;

    this.setState({
      coupons,
      total,
      pages,
      last_page,
      end_page,
    });
  };

  goTo = (path) => this.props.history.push(path);

  goToPage = async (page) => {
    const { limit, pages, search } = this.state;
    const couponsObj = await getCouponsRequest(page, limit, search);
    const coupons = couponsObj.coupons;
    const { start_page, end_page } = calcStartEndPage(page, pages);

    this.setState({
      coupons: coupons,
      current_page: page,
      start_page: start_page,
      end_page: end_page,
    });
  };

  onChangeFilter = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      page: 0,
    });
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.getCoupons();
    }, 300);
  };

  deleteCoupon = async () => {
    const { deleteCouponId } = this.state;
    await deleteCouponRequest(deleteCouponId);
    this.getCoupons();
  };

  openConfirmation = (couponId) => () => {
    this.setState({
      deleteCouponId: couponId,
    });
    this.deleteConfirmationModal.toggle();
  };

  render() {
    const { coupons, pages, current_page, start_page, end_page, search } =
      this.state;

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4" className="d-inline-block">
                    Coupons
                  </CardTitle>
                  <Button
                    className="pull-right mb-4"
                    color="primary"
                    onClick={() => this.goTo("/coupon/create")}
                  >
                    Create Coupon
                  </Button>
                  <FormGroup>
                    <Input
                      placeholder="Search Coupons"
                      type="text"
                      name="search"
                      value={search}
                      onChange={this.onChangeFilter}
                    ></Input>
                  </FormGroup>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Coupon Code</th>
												<th>Discount</th>
                        <th>Validity</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons && coupons.length ? (
                        coupons.map((coupon) => {
                          return (
                            <tr key={coupon._id}>
                              <td>{coupon.name}</td>
                              <td>{coupon.code}</td>
															<td>{coupon.discountType === "Percentage" ? `${coupon.discountValue}%` : coupon.discountType === "Fixed" ? `$${coupon.discountValue}` : "N/A"}</td>
                              <td>{moment(coupon.startDate).format("MM/DD/YY")} - {moment(coupon.endDate).format("MM/DD/YY")}</td>
                              <td className="text-right">
                                <Button
                                  className="btn-link btn-icon"
                                  color="success"
                                  id={`tooltip-view-${coupon._id}`}
                                  size="sm"
                                  onClick={() =>
                                    this.goTo(`/coupon?id=${coupon._id}`)
                                  }
                                >
                                  <i className="tim-icons icon-zoom-split" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-view-${coupon._id}`}
                                >
                                  View
                                </UncontrolledTooltip>
                                <Button
                                  className="btn-link btn-icon"
                                  color="info"
                                  id={`tooltip-edit-${coupon._id}`}
                                  size="sm"
                                  onClick={() =>
                                    this.goTo(`/coupon/edit?id=${coupon._id}`)
                                  }
                                >
                                  <i className="tim-icons icon-pencil" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-edit-${coupon._id}`}
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Button
                                  className="btn-link btn-icon"
                                  color="danger"
                                  id={`tooltip-delete-${coupon._id}`}
                                  size="sm"
                                  onClick={this.openConfirmation(coupon._id)}
                                >
                                  <i className="tim-icons icon-simple-remove" />
                                </Button>
                                <UncontrolledTooltip
                                  delay={0}
                                  target={`tooltip-delete-${coupon._id}`}
                                >
                                  Delete
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7">There are no coupons to show</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {coupons?.length > 0 && (
                    <PaginationComponent
                      pages={pages}
                      current_page={current_page}
                      start_page={start_page}
                      end_page={end_page}
                      goToPage={this.goToPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ConfirmationModal
            ref={(el) => (this.deleteConfirmationModal = el)}
            title="Deletar Coupon?"
            buttons={[
              {
                title: "Cancel",
                className: "btn-primary-override btn btn-primary",
              },
              {
                title: "Delete",
                className: "btn-danger-override btn btn-danger",
                onClick: this.deleteCoupon,
              },
            ]}
          >
            Are you sure you want to delete this coupon?
          </ConfirmationModal>
        </div>
      </>
    );
  }
}

export default Coupons;
