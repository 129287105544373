import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import moment from 'moment';

// Requests
import { getUserRequest } from '../../api/user';
// Helpers
import { formatRole, formatArea } from '../../helpers/strings';

class UserDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          user: null
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const id = params.get('id');

        if (id) {
            const user = await getUserRequest(id);

            this.setState({ 
              user,
            })

        } else {
            this.goTo('/users')
        }
    }
  
    goTo = path => this.props.history.push(path);

    render() {

      const {
        user
      } = this.state;

      const {
        _id,
        email,
        role,
        isAdmin,
        phone,
        emailVerified,
        company
      } = user || {}
        
      return (
          <>
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader className="mb-5">
                    <CardTitle tag="h3">
                      User Details
                    </CardTitle>
                    <Button 
                      className="float-right"
                      color="default"
                      onClick={() => this.goTo('/users')}
                    >
                      Back
                    </Button>
                  </CardHeader>
                  <CardBody>
                    {!user 
                    ? <div>Loading...</div>
                    : <>
                    <div className="typography-line">
                      <h4>
                        <span>ID</span>
                        {_id}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Email</span>
                        {email}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>First Name</span>
                        {user[role].firstName}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Last Name</span>
                        {user[role].lastName}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Company ID</span>
                        {company?._id || 'N/A'}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Company Name</span>
                        {company?.name || 'N/A'}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Email verified</span>
                        {emailVerified ? "Yes" : "No"}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Role</span>
                        {formatRole(role)}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Administrator</span>
                        {isAdmin ? "Yes" : "No"}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Telephone</span>
                        {phone || "N/A"}
                      </h4>
                    </div>               
                    {role === "client" &&
                      <Card className="detail-card">
                        <CardHeader>
                          <h3>Addresses</h3>
                        </CardHeader>
                        <CardBody>
                          {user[role]?.addresses && user[role]?.addresses.length > 0
                            ? user[role].addresses.map((address, index) => {
                              return (
                                <div>
                                  <h4>Address #{index + 1}</h4>
                                  <div className="typography-line">
                                    <h4>
                                      <span>Label</span>
                                      {address.label || 'N/A'}
                                    </h4>
                                  </div>

                                  <div className="typography-line">
                                    <h4>
                                      <span>Street Address</span>
                                      {address.streetAddress || 'N/A'}
                                    </h4>
                                  </div>


                                  <div className="typography-line">
                                    <h4>
                                      <span>Notes</span>
                                      {address.notes || 'N/A'}
                                    </h4>
                                  </div>

                                  <div className="typography-line">
                                    <h4>
                                      <span>City</span>
                                      {address.city || 'N/A'}
                                    </h4>
                                  </div>

                                  <div className="typography-line">
                                    <h4>
                                      <span>State</span>
                                      {address.state || 'N/A'}
                                    </h4>
                                  </div>

                                  <div className="typography-line">
                                    <h4>
                                      <span>Zip Code</span>
                                      {address.zipCode || 'N/A'}
                                    </h4>
                                  </div>
                                </div>
                              )
                            })
                            : <div>User doesn't have any address saved</div>
                          }
                        </CardBody>
                      </Card>
                    }
                    {role === 'driver' && 
                      <>
                        <Card className="detail-card">
                          <CardHeader>
                            <h3>Driver Details</h3>
                          </CardHeader>
                          <CardBody>
                            <div className="typography-line">
                              <h4>
                                <span>Driver ID</span>
                                {user['driver']._id || 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Driver Area</span>
                                {user['driver'].area ? formatArea(user['driver'].area) : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Avatar Image</span>
                                {user['driver'].avatar ? <a href={user['driver'].avatar} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Drivers License Image</span>
                                {user['driver'].driversLicenseImage ? <a href={user['driver'].driversLicenseImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Insurance Card Image</span>
                                {user['driver'].insuranceCardImage ? <a href={user['driver'].insuranceCardImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            </CardBody>
                        </Card>
                        <Card className="detail-card">
                          <CardHeader>
                            <h3>Vehicle Details</h3>
                          </CardHeader>
                          <CardBody>
                            <div className="typography-line">
                              <h4>
                                <span>Car Registration Image</span>
                                {user['driver'].carRegistrationImage ? <a href={user['driver'].carRegistrationImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Car Image 1</span>
                                {user['driver'].carFirstImage ? <a href={user['driver'].carFirstImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Car Image 2</span>
                                {user['driver'].carSecondImage ? <a href={user['driver'].carSecondImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Car Image 3</span>
                                {user['driver'].carThirdImage ? <a href={user['driver'].carThirdImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                            <div className="typography-line">
                              <h4>
                                <span>Car Image 4</span>
                                {user['driver'].carFourthImage ? <a href={user['driver'].carFourthImage} target="_blank" rel="noreferrer">Show Image</a>  : 'N/A'}
                              </h4>
                            </div>
                          </CardBody>
                        </Card>
                      </>
                    }   

                    <div className="typography-line">
                      <h4>
                        <span>Created At</span>
                        {user.createdAt ? moment(user.createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                      </h4>
                    </div>
                    <div className="typography-line">
                      <h4>
                        <span>Updated At</span>
                        {user.updatedAt ? moment(user.updatedAt).format("YYYY-MM-DD HH:mm:ss") : "N/A"}
                      </h4>
                    </div>
                    </>
                    }
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
}

export default UserDetails;
