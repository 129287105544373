import { apiRequest} from './index'

export const getCorporationsRequest = (page, limit, search, role) => {
  return apiRequest(
    `/admin/corporations?page=${page}&limit=${limit}&search=${search}&role=${role}`,
    {
      method: 'GET'
    },
    true
  )
}

export const getCorporationRequest = (id) => {
  return apiRequest(
    `/admin/corporations/${id}`,
    {
      method: 'GET'
    },
    true
  )
}

export const updateCorporationRequest = (corporation) => {
  return apiRequest(
    `/admin/corporations/${corporation._id}`,
    {
			method: 'PUT',
			body: JSON.stringify(corporation)
    },
    true
  )
}

export const createCorporationRequest = (corporation) => {
  return apiRequest(
    `/admin/corporations`,
    {
			method: 'POST',
			body: JSON.stringify(corporation)
    },
    true
  )
}

export const deleteCorporationRequest = (corporationId) => {
  return apiRequest(
    `/admin/corporations/${corporationId}`,
    {
			method: 'DELETE'
    },
    true
  )
}

export const getCorporationStatsRequest = () => {
  return apiRequest(
    `/admin/corporation-stats`,
    {
      method: 'GET'
    },
    true
  )
}